import API from "@/api"
let data_source = null
let uav_data = null
import dayjs from 'dayjs'
import gasData from '../../../data/gas.json';
import _ from 'lodash';
import * as turf from '@turf/turf';
import Bus from "@/assets/ligature.js";

let disasterLayer3 = new Cesium.TileMapServiceImageryProvider({
  url: `${process.env.VUE_APP_DISASTER_URL}/xiaoqinghe/jiu/`
});
let disasterLayer4 = new Cesium.TileMapServiceImageryProvider({
  url: `${process.env.VUE_APP_DISASTER_URL}/xiaoqinghe/xin/`
});
let disasterHandler = null;
let disasterEarthAtRight = null;
let disasterEarthAtLeft = null;

class Parser {
  static uav_data_parser (metadata) {
    let data = JSON.parse(metadata)
    return data
  }
}

function tree2list (tree, list = []) {
  tree.forEach(item => {
    if (item.devices && item.devices.length) {
      item.devices.forEach(d => list.push(d))
    }
    if (item.child && item.child.length) {
      let arr = tree2list(item.child)
      arr.forEach(d => list.push(d))
    }
  })
  return list
}

function tree2list_org (tree, list = []) {
  tree.forEach(item => {
    list.push(item)
    if (item.child && item.child.length) {
      // 为了不失去引用，所以写成遍历然后 push
      let arr = tree2list_org(item.child)
      arr.forEach(_item => list.push(_item))
    }
  })
  return list
}

let gasDataSource = new Cesium.CustomDataSource("gasDataSource");
let gasModelEntity1 = null;
let heatMap = null;
let gasLabel = '';
let gasDataLen = gasData.length;
let gasMaxValue = 0;
let gasMinValue = 0;
let gasColorObj = {
  // "0.0": "#e6ff1a",
  // "0.1": "#e6ff1a",
  // "0.2": "#ccff33",
  // "0.3": "#b3ff4d",
  // "0.4": "#99ff66",
  // "0.5": "#80ff80",
  // "0.6": "#66ff99",
  // "0.7": "#4dffb3",
  // "0.8": "#33ffcc",
  // "0.9": "#1affe6",
  // "1.0": "#00ffff",
  "0.0": "#5c9f4a",
  "0.1": "#5c9f4a",
  "0.2": "#6c964a",
  "0.3": "#7d8e49",
  "0.4": "#8d8549",
  "0.5": "#9e7d48",
  "0.6": "#af7447",
  "0.7": "#bf6c47",
  "0.8": "#d06346",
  "0.9": "#e05b46",
  "1.0": "#f15245",
}
let gasStopFlag = false;

export default {
  //请求无人机控制权限
  async jursdiction () {
    let res = await API.FCKERNEL.checkUavControl({
      deviceHardId: this.uav.NoticeData.deviceHardId
    })
    if (res.code == 200) {
      this.$message.success(res.msg)
      this.uav.NoticeFlag = false
    }
  },
  async init_data_source () {
    data_source = new Cesium.CustomDataSource("FCKERNEL_LAYER")
    await viewer.dataSources.add(data_source)
  },
  destroy_data_source () {
    if (data_source) {
      data_source.entities.removeAll()
      viewer.dataSources.remove(data_source, true)
      data_source = null
    }
  },

  list_filter (search) {
    let device_list = tree2list(this.uav.list)
    let org_list;
    let org_ids = []
    if (search) {
      device_list.forEach(item => item.display = false)
      let filter_device = device_list.filter(item => item.deviceName && (item.deviceName.indexOf(search) !== -1))
      filter_device.forEach(item => {
        item.display = true
        org_ids.push(item.org_id)
      })

      org_list.forEach(item => item.display = false)
      let filter_org = org_list.filter(item => item.orgName && (item.orgName.indexOf(search) !== -1))
      function each_display (tree) {
        tree.forEach(item => {
          item.display = true
          if (item.child && item.child.length) {
            each_display(item.child)
          }
          if (item.devices && item.devices.length) {
            item.devices.forEach(device => device.display = true)
          }
        })
      }
      filter_org.forEach(item => each_display([item]))

      filter_org.forEach(item => org_ids.push(item.id))
      let pid = 0
      org_ids.forEach(id => {
        pid = id
        while (pid >= 0) {
          let item = org_list.find(item => item.id === pid)
          if (item) {
            item.display = true
            pid = item.pid
          } else {
            pid = -1
          }
        }
      })
    } else {
      device_list.forEach(item => item.display = true)
      org_list.forEach(item => item.display = true)
    }
  },

  async list (search) {
    /*  if (this.uav.list && this.uav.list.length) {
         this.accident_list_filter(search, 'list')
         return
     } */
    let index = this.$route.query.page;
    let type = ''
    if (index == 0) {
      type = 'patrol'
    } else if (index == 1) {
      type = 'traffic'
    } else if (index == 2) {
      type = 'food'
    } else if (index == 3) {
      type = 'net'
    } else if (index == 4) {
      type = 'water'
    } else if (index == 5) {
      type = 'crimes'
    }
    // else if (index == 6) {
    //   type = 'crimes'
    // }
    let list = await API.ACCIDENT.strikeOrganTree({
      type
    })
    try {
      if (list[0].child) {
        list[0].child.sort(function (a, b) {
          return a.sort - b.sort
        })
      }
    } catch (error) {

    }

    function each (tree, flag) {
      return tree.map((item) => ({
        ...item,
        collapse: flag,
        // display: true,
        devices:
          item.devices && item.devices.length
            ? item.devices.map((device) => ({
              ...device,
              _location: false,
              _video: false,
              _airway: false,
              _control: false,
              org_id: item.id,
              // display: true
            }))
            : [],
        child: item.child && item.child.length ? each(item.child, true) : [],
      }));
    }

    if (list && list.length) {
      list = each(list)
    }
    this.uav.list = list || []

    // return;
    // console.log();

    // 气体检测标志
    if (index == 6) {
      console.log('气体检测进入');
      this.uav.gas = true;
      this.nsetLeftBoxShowHandle(false);
      Bus.$emit('gas_init');
      return;
    } else {
      console.log('气体检测退出');
      this.uav.gas = false;
      this.nsetLeftBoxShowHandle(true);
      this.uav_claerGas();
    }
    // 灾情对比标志
    // debugger
    if (index == 7) {
      this.uav.disaster = true;
      this.$nextTick(() => {
        this.uav_disaster();
        this.nsetLeftBoxShowHandle(false);
      });
      return;
    } else {
      this.uav.disaster = false;
      this.uav_remove_disaster();
      this.nsetLeftBoxShowHandle(true);
    }
  },

  subscribe (topic, callbck) {
    this.mqtt_subscribe(topic, callbck)
  },
  init_ws () {
    let {
      user_info
    } = this
    let _this = this
    let username = user_info.username
    let token = user_info["mmc-identity"] + user_info["FLYINGSESSIONID"]
    let appId = user_info.appid
    let ws_url = process.env.VUE_APP_WS_URL
    let ws = this.uav.ws = new WebSocket(ws_url);

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          type: 100,
          systemCode: "mmc",
          state: 1,
          username,
          token,
          appId,
        })
      );
    };
    ws.onmessage = (e) => {
      // 鹰视监听禁飞区
      // let metadata = JSON.parse(e.data)
      let metadata = null;
      try {
        metadata = JSON.parse(e.data)
        // console.log(metadata, '无人机原始数据，可能引起地图压力过大20');
      }
      catch (e) {
        console.error(e.data);
      }
      // console.log(metadata, '无人机原始数据，可能引起地图压力过大');



      if (metadata && metadata.msgnum === 4120) {
        _this.$el_message('admin账号控制中，请稍后控制！', () => { }, 'error')
      }
      if (metadata && metadata.type === 300) {
        _this.$el_message('执行成功！')
      }
      if (metadata && metadata.type === 300 && metadata.cmdFunction == 9000) {
        _this.$el_message('航线上传成功！')
        // if (this.takeOff == true) {
        this.fly_take_off()
        // }
      }
      // if (metadata && metadata.type === 300 && metadata.cmdFunction == 9001) {
      //     this.uav.airway = metadata
      //     let {
      //         data: {
      //             points
      //         }
      //     } = this.uav.airway
      //     this.uav_handleAirLinePreview(points)
      // }
      // 监听无人机上锁
      if (metadata && metadata.type === 810) {
        let {
          uav: {
            control
          }
        } = this

        let deviceHardId = control.deviceHardId
        if (deviceHardId == metadata.deviceHardId) {
          // this.uav.taskStatus = true
        }
      }
      // // 视频推流状态更新 弃用
      // if (metadata && metadata.type === 999) {
      //     if (metadata.pushStatus) {
      //         this.uav_update_device_video_status(metadata.pushStatus, metadata.deviceHardId)
      //     }
      // }

      if (metadata && metadata.msgnum) {
        // 设备上线
        if (metadata.msgnum === 4132) {
          let deviceHardId = metadata && metadata.deviceHardId;
          this.uav_update_device_report_status(1, deviceHardId, 'list')
          this.uav_update_device_report_status(1, deviceHardId, 'mountList')
        }
        // 设备离线
        if (metadata.msgnum === 4121) {
          let deviceHardId = metadata && metadata.deviceHardId;
          this.uav_update_device_report_status(0, deviceHardId, 'list')
          this.uav_update_device_report_status(0, deviceHardId, 'mountList')
        }
      } else {
        // 无人机上报数据
        if (metadata && metadata.deviceData) {
          uav_data = JSON.parse(metadata.deviceData)
          //模拟飞机上升
          // uav_data.data.uavInfo.altitude += (height += 0.1);
          if (uav_data && uav_data.deviceHardId) {
            if (uav_data.data && uav_data.data.uavInfo) {
              if (this?.uav?.control?.device?.deviceHardId == uav_data.deviceHardId && this.wsShow) {
                this.uav_update_control(this.uav.control.device, uav_data.data.uavInfo)
                this.uav_update_mounts(this.uav.control.device, uav_data.data.mountInfo);
                this.uav_update_control_battery(this.uav.control.device, uav_data.data.uavInfo);
                // this.uav_update_control(uav_data)
                if (uav_data.data.uavInfo.push_status) {
                  let push_status = uav_data.data.uavInfo.push_status
                  // 视频推流数据刷新
                  // this.uav_update_device_video_status(push_status, uav_data.deviceHardId, 'mountList')
                  // this.uav_update_device_video_status(push_status, uav_data.deviceHardId, 'list')
                }
              }
            }
            if (this.wsShow) {
              let lon = uav_data.data.uavInfo.longitude;
              let lat = uav_data.data.uavInfo.latitude;
              let height = uav_data.data.uavInfo.height;
              if (lon && lat && height) {
                this.uav_update_locations(uav_data)
              }
              // this.uav_update_locations(uav_data)
            }
          }
        }
        if (metadata && metadata.type === 800) {
          // this.uav.imgUrl = metadata.url
          // this.uav.imgVisible = true
        }
      }
    };

    ws.onerror = (error) => {
      // console.log("error", error);
    };

    ws.onclose = (data) => { };
  },
  async control_ws (cate) {
    // let {
    //     flight_line_id,
    //     id
    // } = this.$store.state.task.controlPanelData;
    let deviceHardId = this.uav.control.device.deviceHardId
    // let taskId = this.$store.state.fckernel.taskId
    // if (!deviceHardId) {
    //     return false
    // }
    let username = this.user_info.username
    // if (cate > 8 && taskId) {} else {
    //     return this.$message.info('请选择航线')
    // }
    if (cate == 11) {
      // 安全降落
      this.$confirm('请确认是否执行安全降落操作?', '安全确认', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        customClass: "uav_controlPane",
        showClose: false
      }).then(() => {
        this.uav_send_ws({
          type: 200,
          systemCode: 'mmc',
          state: 1,
          username,
          data: {
            cmdFunction: 2116,
          },
          deviceHardId,
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    } else if (cate == 12) {
      // 一键返航
      this.$confirm('请确认是否进行一键返航操作?', '返航确认', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        customClass: "uav_controlPane",
        showClose: false
      }).then(() => {
        this.uav_send_ws({
          type: 200,
          systemCode: 'mmc',
          state: 1,
          username,
          data: {
            cmdFunction: 2112,
          },
          deviceHardId,
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    } else if (cate == 13) {
      // 航线模式
      this.uav_send_ws({
        type: 200,
        systemCode: 'mmc',
        state: 1,
        username,
        data: {
          cmdFunction: 2115,
        },
        deviceHardId,
      })

    } else if (cate == 17) {
      // 暂停飞行
      this.uav_send_ws({
        type: 200,
        systemCode: 'mmc',
        state: 1,
        username,
        data: {
          cmdFunction: 2117,
        },
        deviceHardId,
      })
    }
  },

  send_ws (data) {
    this.uav.ws && this.uav.ws.send(JSON.stringify(data))
  },
  async fn (cate, device, orgName) {
    console.log('===========场景面板============');

    this.uavId = device

    if (device.reportStatus !== 1) {
      this.$message('无人机不在线');
      return
    }
    if (!data_source) {
      await this.uav_init_data_source()
    }
    // this.controlMenuFlag = !this.controlMenuFlag
    this.controlMenuFlag = false

    // 将列表缩进左边
    // this.collapseFlagfn()
    let uavCate = device.cateName == 'px4' ? 'PX4' : 'APM'
    let topic = `${uavCate}/RECEIVE/${device.deviceHardId}`
    if (cate === 1) {
      // 定位
      device._location = !device._location
      if (device._location) {
        this.uav_show_location(device)
      } else {
        this.uav_hide_location(device)
      }
    } else if (cate === 2) {
      // 航线
      device._airway = !device._airway
      if (device._airway) {
        this.uav_show_airway(device)
      } else {
        this.uav_hide_airway(device)
      }
    } else if (cate === 3) {
      // 视频
      device._video = !device._video
      if (device._video) {
        this.uav_show_video(device, orgName)
      } else {
        this.uav_hide_video(device)
      }
    } else if (cate === 4) {
      // let list = await API.FCKERNEL.ListUAV()
      // 控制面板
      device._control = !device._control
      if (device._control) {
        this.controlMenuFlag = false
        device._video = true
        this.uav_show_video(device, orgName)
        this.uav_show_control(device)
        this.ControlFlag = true
        // this.flag = false
        this.VideoTomapFlag = true
        // 打开 任务面板
        if (device.stationType == 1) {
          this.wsShow = false
          this.uav_subscribe(topic, () => {
            console.log(`订阅无人机数据：topic:${topic}`);
          })
        } else {
          if (this.mqtt.client) {
            this.mqtt.client.end();
            this.mqtt.client = null;
          }
          this.wsShow = true
          this.uav_send_ws({
            type: 1126,
            deviceHardId: device.deviceHardId
          })
        }
        // this.collapseFlag = true
      } else {
        this.controlMenuFlag = false
        this.VideoTomapFlag = false
        // if (this.mqtt.client) {
        //     this.mqtt.client.end()
        //     this.mqtt.client = null
        // }
        // // 取消订阅
        device._video = false
        this.ControlFlag = false
        // this.mqtt_unsubscribe(topic)
        this.uav_hide_control(device)
        this.uav_hide_video(device)
      }

    } else if (cate === 5) {
      // 查看是否有接管权限
      let res = await API.FCKERNEL.getTakeOverInfo({ deviceHardId: device.deviceHardId })
      if (res.code == 201) {
        this.$message.warning(res.msg)
        return false
      } else if (res.code == 200) {
        let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device.deviceHardId })
        if (res.code == 201) {
          let text = `是否请求接管 ${orgName} 下的 ${device.deviceName}`
          this.uav.NoticeData.deviceHardId = device.deviceHardId
          this.uav.NoticeData.content = text
          this.uav.NoticeFlag = true
        } else if (res.code == 200) {
          return this.$message.warning(res.msg);
        }

      }

    }
  },
  trans_location_data (device, uav_data) {
    if (!uav_data) {
      return uav_data
    }

    let uavInfo = uav_data.uavInfo
    let position = null;
    let positions = [];
    let orientation = null
    try {
      position = Cesium.Cartesian3.fromDegrees(
        uavInfo.longitude * 1,
        uavInfo.latitude * 1,
        (uavInfo.altitude * 1)
      );
      let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
        pitch = Cesium.Math.toRadians(uavInfo.pitch),
        roll = Cesium.Math.toRadians(uavInfo.roll),
        hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll)
      orientation = Cesium.Transforms.headingPitchRollQuaternion(
        position,
        hpr
      );


      if (uavInfo.longitude != 0 && uavInfo.latitude != 0) {
        positions.push(position);
      }
    } catch (e) {
      this.$message({
        type: 'warning',
        message: '定位失败'
      })
    }

    return {
      position,
      positions,
      orientation,
      device
    };
  },
  trans_location_datas (device, uav_data) {
    if (!uav_data) {
      return uav_data
    }

    let uavInfo = uav_data.data.uavInfo
    uavInfo.altitude += 10;
    let position = Cesium.Cartesian3.fromDegrees(
      uavInfo.longitude * 1,
      uavInfo.latitude * 1,
      (uavInfo.altitude * 1)
    );
    let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
      pitch = Cesium.Math.toRadians(uavInfo.pitch),
      roll = Cesium.Math.toRadians(uavInfo.roll),
      hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
      orientation = Cesium.Transforms.headingPitchRollQuaternion(
        position,
        hpr
      );

    let positions = [];
    if (uavInfo.longitude != 0 && uavInfo.latitude != 0) {
      positions.push(position);
    }

    return {
      position,
      positions,
      orientation,
      device
    };
  },
  trans_mqtt_location_data (device, uav_data) {
    if (!uav_data) {
      return uav_data
    }
    // 获取航线
    let location = uav_data.locationCoordinate3D
    let position = Cesium.Cartesian3.fromDegrees(
      location.longitude * 1,
      location.latitude * 1,
      location.altitude * 1
    );


    let positions = [];
    let attitude = uav_data.attitude
    let yaw = Cesium.Math.toRadians(attitude.yaw + 90),
      pitch = Cesium.Math.toRadians(attitude.pitch),
      roll = Cesium.Math.toRadians(attitude.roll),
      hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
      orientation = Cesium.Transforms.headingPitchRollQuaternion(
        position,
        hpr
      );
    if (this.uav.model) {
      let model = this.uav.model
      const curPos = model.position.getValue(
        Cesium.JulianDate.now()
      );
      if (curPos) {
        const curCart = new Cesium.Cartesian3(curPos.x, curPos.y, curPos.z);
        const afterCart = new Cesium.Cartesian3(position.x, position.y, position.z);
        if (curCart.equals(afterCart)) {
        } else {
          // 航线数据
          positions.push(position);
        }
      } else {
      }
    }

    return {
      position,
      positions,
      orientation,
      device
    };
  },
  Cartesian3_to_WGS84 (point) {
    var cartesian33 = new Cesium.Cartesian3(point.x, point.y, point.z);
    var cartographic = Cesium.Cartographic.fromCartesian(cartesian33);
    var lat = Cesium.Math.toDegrees(cartographic.latitude);
    var lng = Cesium.Math.toDegrees(cartographic.longitude);
    var alt = cartographic.height;
    return { lng: lng, lat: lat, alt: alt };

  },
  // 鹰视  航线 高度 加10
  async show_location (device) {
    if (!this.wsShow) {
      if (!this.linevisible) {
        this.linevisible = true
        viewer.trackedEntity = null
        let uav_data = await API.FCKERNEL.GetLastUavData({
          deviceHardId: device.deviceHardId
        })

        if (!uav_data) {
          this.$el_message("暂未找到位置信息", () => { }, "error")
          return uav_data
        }
        if (!data_source) {
          await this.uav_init_data_source()
        }
        let entities = data_source.entities
        let cesium_layer = this.g_cesium_layer()
        try {
          //由于3D地图悬浮于2D地图表面，需要增加无人机模型的海拔高度，不然会被3D地图遮挡
          uav_data.uavInfo.altitude += 10;
        } catch (e) { }
        // console.log('海拔高度增加10米', uav_data.uavInfo.altitude);
        let data = this.uav_trans_location_data(device, uav_data)
        let deviceHardId = device.deviceHardId
        let model = entities.getById(`fckernel_model_${deviceHardId}`);
        this.uav.online[deviceHardId] = data

        if (!model) {
          this.uav.online[deviceHardId].entity_id = `fckernel_model_${deviceHardId}`
          model = this.uav.model = cesium_layer.add_model(entities, this.uav.online[deviceHardId])

          //cartesian3转wgs84, 通过经纬度高度1000米创建新cartesian3，并设置相机飞入此位置
          var ellipsoid = viewer.scene.globe.ellipsoid;
          var cartesian3 = model.position.getValue(Cesium.JulianDate.now());
          var cartogtraphic = ellipsoid.cartesianToCartographic(cartesian3);
          var lat = Cesium.Math.toDegrees(cartogtraphic.latitude);
          var lng = Cesium.Math.toDegrees(cartogtraphic.longitude);

          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(lng, lat, 1000), //model.position.getValue(Cesium.JulianDate.now()),
            maximumHeight: 100,
            complete: () => {
              viewer.trackedEntity = model
            }
          })
          model.viewFrom = new Cesium.Cartesian3(0, 0, 1000)

          this.uav.model = model
        }

        let polyline = entities.getById(`fckernel_polyline_${deviceHardId}`)
        if (!polyline) {
          let model = cesium_layer.add_polyline(entities, {
            ...data,
            entity_id: `fckernel_polyline_${deviceHardId}`,
            options: {
              show: true
            }
          })

        }
      } else {
        this.linevisible = false
        this.uav_remove_model(device)
      }
    } else {
      if (!this.linevisible) {
        this.linevisible = true
        let uav_data = Parser.uav_data_parser(device.latestData)
        this.uav_show_airways(device, uav_data)
      } else {
        this.linevisible = false
        this.uav_hide_locations(device, uav_data)
      }
    }

  },
  hide_locations (device, uav_data) {
    let cesium_layer = this.g_cesium_layer()
    let viewer = cesium_layer.viewer()
    viewer.trackedEntity = null
    let entities = viewer.entities.getById(this.uav.online[device.deviceHardId].entity_id)
    viewer.entities.remove(entities)
  },
  show_airways (device, uav_data) {
    // console.log(device,uav_data);
    let entities = window.viewer.entities
    if (!device._location) {
      device._location = true
      this.uav_show_locations(device, uav_data)
    }
    let entity = entities.getById(`observe_polylineUav_${device.deviceHardId}`)
    entity && (entity.polyline.show = true)
  },
  show_locations (device, uav_data) {
    // if (!data_source) {
    //     return false
    // }
    if (!uav_data) {
      // this.$el_message("暂未找到位置信息", () => { }, "error")
      return uav_data
    }

    // let devices = tree2list(this.uav.list)
    // devices.map(item => item.deviceHardId != device.deviceHardId && (item._location = false))
    let { deviceHardId } = device
    let cesium_layer = this.g_cesium_layer()
    let viewer = window.viewer
    let data = this.uav_trans_location_datas(device, uav_data)
    let model = window.viewer.entities.getById(`observe_model_${deviceHardId}`)
    if (!model) {
      this.uav.online[deviceHardId] = data
      this.uav.online[deviceHardId].entity_id = `observe_model_${data.device.deviceHardId}`
      model = cesium_layer.add_model(window.viewer.entities, this.uav.online[deviceHardId])
    }

    let polyline = window.viewer.entities.getById(`observe_polylineUav_${deviceHardId}`)
    if (!polyline) {
      cesium_layer.add_polyline(window.viewer.entities, {
        ...data,
        entity_id: `observe_polylineUav_${data.device.deviceHardId}`,
        options: {
          show: false
        }
      })
    }

    viewer.camera.flyTo({
      destination: model.position.getValue(Cesium.JulianDate.now()),
      maximumHeight: 10,
      complete: () => {
        viewer.trackedEntity = model;
      }
    })
    model.viewFrom = new Cesium.Cartesian3(0, 0, 1000)
  },
  remove_model (device) {
    // 删除飞控 无人机 模型航线
    if (data_source) {
      let entities = data_source.entities
      entities.removeById(`fckernel_model_${device.deviceHardId}`)
    }
    this.linevisible = false
    this.uav_hide_airway(device)
  },
  hide_location (device, uav_data) {
    viewer.trackedEntity = null
  },
  update_location (device, uav_data) {
    let online = this.uav.online[device.deviceHardId]
    if (online) {
      let data = this.uav_trans_mqtt_location_data({ device }, uav_data)
      online.orientation = data.orientation
      online.position = data.position
      if (data.positions.length > 0) {
        online.positions.push(data.position)
      }
    }
  },
  update_locations (uav_data) {
    let online = this.uav.online[uav_data.deviceHardId]
    // this.uav.datalist = uav_data.data
    if (online) {
      let data = this.uav_trans_location_datas({}, uav_data)
      online.orientation = data.orientation
      online.position = data.position
      online.positions.push(online.position)
    }
  },
  async show_airway (device) {
    let polyline = entities.getById(`fckernel_polyline_${deviceHardId}`)
    if (!polyline) {
      cesium_layer.add_polyline(entities, {
        ...data,
        entity_id: `fckernel_polyline_${data.device.deviceHardId}`,
        options: {
          show: true
        }
      })
    }
  },
  hide_airway (device) {
    if (!data_source) {
      return false
    }

    let entities = data_source.entities
    let entity = entities.getById(`fckernel_polyline_${device.deviceHardId}`)
    entity && (entity.polyline.show = false)
    if (device.deviceHardId) {
      try {
        // 删除飞机模型
        window.viewer.entities.removeById(`fckernel_polyline_${device.deviceHardId}`)
      } catch (error) {
      }
    }
  },

  // 显示视频
  async show_video (device, orgName) {
    // 先清空所有无人机控制的状态
    try {
      let device_list = tree2list(this.uav.list)
      device_list.forEach(item => {
        if ((item.deviceHardId !== device.deviceHardId) && item._control) {
          item._video = false
          this.uav_hide_control(item)
        }
      })

      this.$set(this.uav.control, "device", device)
    } catch (error) {

    }
    // onLine
    let data = await API.FCKERNEL.GetDeviceStreamAddressByDeviceId({ deviceHardId: device.deviceHardId })
    if (!data.list) {
      device._video = false;
      return this.$message.info('暂无视频')
    } else {
      let index = this.uav.videos.findIndex(item => item === undefined)
      if (index !== -1) {
        this.uav.videos.splice(index, 1, {
          ...device,
          orgName,
          videos: data,
        })
      } else {

        if (this.uav.videos.length < 4) {
          this.uav.videos = [{
            ...device,
            orgName,
            videos: data
          }]
          // this.uav.videos.push({
          //     ...device,
          //     orgName,
          //     videos: data,
          // })
        } else {
          this.uav_hide_video(this.uav.videos[0])
          this.uav_show_video(device, uav_data)
        }
      }
    }

  },
  hide_video (device) {

    let { deviceHardId } = device
    let index = this.uav.videos.findIndex(item => item && (item.deviceHardId === deviceHardId))

    let devices = tree2list(this.uav.list)
    device = devices.find(item => item.deviceHardId === deviceHardId)

    if (index !== -1) {
      device._video = false
      this.uav.videos.splice(index, 1)
    }
  },

  show_control (device) {
    // 先清空所有无人机控制的状态
    let device_list = tree2list(this.uav.list)
    device_list.forEach(item => {
      if ((item.deviceHardId !== device.deviceHardId) && item._control) {
        item._control = false
        this.uav_hide_control(item)
      }
    })

    this.$set(this.uav.control, "device", device)

  },
  hide_control (device) {
    this.uav.control.device = null
    this.uav.control.device = null
    this.uav.control.data = null
    this.uav.control.battery = null
    this.uav.control.mount = null
    this.uav.control.mounts = []
    this.uav.control.mounts = []

  },
  update_control (device, uav_data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      this.$set(this.uav.control, "data", uav_data)
    }
  },
  update_control_battery (device, battery_data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      this.$set(this.uav.control, "battery", battery_data)
    }
  },
  update_channelData (device, channelData) {
    // if (this.uav.control.device.deviceHardId === device.deviceHardId) {
    this.$set(this.uav.control, "channelData", channelData)
    // }
  },
  // 无人机提示信息
  update_control_info (device, battery_data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      this.uav.control.info.push(battery_data)
    }
  },
  update_mount (device, data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      let mount = this.mount.list.find(item => item.name === data.gimbalName)
      if (mount) {
        let find_item = this.uav.control.mounts.find(item => item.name === data.gimbalName)
        if (!find_item) {
          this.uav.control.mounts.push({
            ...data,
            ...mount
          })
        }
      }
    }
  },
  update_mounts (device, data) {
    this.uav.control.mounts = data
    this.mount.list.forEach((item, i) => {
      this.uav.control.mounts.forEach((val, j) => {
        if (val.mountName === item.name) {
          val.name = item.name
          val.icon = item.icon
          val.component = item.component
        }
      })
    })
  },
  change_mount (mount) {
    this.$set(this.uav.control, "mount", mount)
  },
  mount_directive (data) {
    let mount = this.uav.control.mount

    if (mount) {
      this.uav_mqtt_fn({
        type: 514,
        data: {
          mountId: mount.mountId,
          payload: data
        }
      })
    }
  },
  move_directive (data) {
    if (data.mountId && data.data) {
      if (this.wsShow) {
        let sendData = {
          "type": 200,
          "systemCode": "mmc",
          "state": 1,
          "username": this.user_info && this.user_info.username,
          "height": 0,
          "idlingTime": 0,
          "data": {
            "cmdControlType": 7000,
            "cmdFunction": null,
            "cmdState": null,
            "cmdValue": null,
            "cmdBuffer": data.data.join(','),
            "cmdName": data.name
          },
          "deviceHardId": data.deviceHardId
        }
        this.uav_send_ws(sendData)
      } else {
        this.uav_mqtt_fn({
          type: 514,
          data: {
            mountId: data.mountId,
            payload: data.data
          }
        })
      }

    }
  },

  /**
   * 切换无人机模式
   * { type: 513, data: mode }
   * @param {*} data 
   */
  change_mode (mode) {
    this.uav_mqtt_fn({ type: 513, data: mode.data })
  },

  update_org_status (cate, id) {
    let list = tree2list_org(this.uav.list)
    let org_id = id
    while (org_id) {
      let org = list.find(x => x.id === org_id)
      if (org) {
        if (cate === 1) {
          org.offline -= 1;
          org.online += 1;
        }
        if (cate === 0) {
          org.offline += 1;
          org.online -= 1;
        }
        org_id = org.pid
      } else {
        org_id = null
      }
    }
  },

  /**
   * cate { -1: 离线, 1: 上线 }
   * @param {*} cate 
   * @param {*} deviceHardId 
   */
  update_device_report_status (cate, deviceHardId) {
    let devices = tree2list(this.uav.list)
    let did = this.uav.control.device && this.uav.control.device.deviceHardId
    devices.map(item => {
      if (item.deviceHardId == deviceHardId) {
        if (item.reportStatus !== cate) {
          item.reportStatus = cate
          if (cate === 1) {
            this.uav_update_org_status(1, item.org_id)
          } else if (cate == 0) {
            this.uav_update_org_status(0, item.org_id)
            item._control = false
            // // 视频推流状态关闭
            // item._videoStatus = 0
            if (did && (did == deviceHardId)) {
              this.uav.control = {}
            }
          }
        }
      }
    })
  },

  /**
   * 无人机 mqtt 发送指令
   * @param {*} data 
   */
  mqtt_fn (data, type, device = this.uav.control.device) {
    if (device) {
      let uavCate = device.cateName == 'px4' ? 'PX4' : 'APM'
      // let uavCate = device.cateName == 'PX4'
      let topic = `${uavCate}/OBTAIN/${device.deviceHardId}`
      this.mqtt_publish(topic, data, e => {
        // console.log('发送指令', data, 'topic', topic);
      })
    }
  },

  // 显示灾情对比
  disaster () {
    console.log('灾情对比');
    let layers = window.viewer.imageryLayers;
    disasterEarthAtLeft = layers.addImageryProvider(disasterLayer3);
    disasterEarthAtLeft.splitDirection = Cesium.SplitDirection.LEFT;
    disasterEarthAtRight = layers.addImageryProvider(disasterLayer4);
    disasterEarthAtRight.splitDirection = Cesium.SplitDirection.RIGHT;
    window.viewer.camera.flyTo({
      destination: Cesium.Rectangle.fromDegrees(118.68526759888, 37.2243122531325, 118.999401631797, 37.2990256057547),
      duration: 3.0
    });
    let slider = document.getElementById("disasterSlider");
    // console.log(slider, 'sliderslidersliderslider');
    window.viewer.scene.splitPosition = slider.offsetLeft / slider.parentElement.offsetWidth - 0;//0.008
    disasterHandler = new Cesium.ScreenSpaceEventHandler(slider);
    let moveActive = false;
    function move (movement) {
      if (!moveActive) {
        return;
      }
      let relativeOffset = movement.endPosition.x;
      let splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
      slider.style.left = `${100.0 * splitPosition}%`;
      window.viewer.scene.splitPosition = splitPosition;
      window.viewer.scene.forceRender();
    }
    disasterHandler.setInputAction(function () {
      moveActive = true;
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
    disasterHandler.setInputAction(function () {
      moveActive = true;
    }, Cesium.ScreenSpaceEventType.PINCH_START);

    disasterHandler.setInputAction(move, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    disasterHandler.setInputAction(move, Cesium.ScreenSpaceEventType.PINCH_MOVE);

    disasterHandler.setInputAction(function () {
      moveActive = false;
    }, Cesium.ScreenSpaceEventType.LEFT_UP);
    disasterHandler.setInputAction(function () {
      moveActive = false;
    }, Cesium.ScreenSpaceEventType.PINCH_END);
  },
  // 移出灾情对比
  remove_disaster () {
    if (disasterEarthAtRight) {
      window.viewer.imageryLayers.remove(disasterEarthAtRight);
    }
    if (disasterEarthAtLeft) {
      window.viewer.imageryLayers.remove(disasterEarthAtLeft);
    }
    if (disasterHandler) {
      disasterHandler.removeInputAction(Cesium.ScreenSpaceEventType.PINCH_END)//移除事件
      disasterHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP)//移除事件
      disasterHandler.removeInputAction(Cesium.ScreenSpaceEventType.PINCH_MOVE)//移除事件
      disasterHandler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE)//移除事件
      disasterHandler.removeInputAction(Cesium.ScreenSpaceEventType.PINCH_START)//移除事件
      disasterHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN)//移除事件
    }
  },

  // 每次鼠标选择监测类型之后加载数据
  async initGas (type) {
    let len = gasData.length;
    let lonArr = [], latArr = [];
    for (let i = 0; i < len; i++) {
      lonArr.push(gasData[i].lon);
      latArr.push(gasData[i].lat);
    }
    let lonMin = _.min(lonArr);
    let lonMax = _.max(lonArr);
    let latMin = _.min(latArr);
    let latMax = _.max(latArr);
    let disX = 20, disY = 20;
    let recArr = this.uav_grid(lonMin, lonMax, latMin, latMax, disX, disY);

    await window.viewer.dataSources.remove(gasDataSource);
    await window.viewer.dataSources.add(gasDataSource);

    for (let i = 0; i < recArr.length; i++) {
      let a = recArr[i][0];
      let b = recArr[i][1];
      let c = recArr[i][2];
      let d = recArr[i][3];
      let midPosLon = (a + c) / 2;
      let midPosLat = (b + d) / 2;
      // 根据网格范围计算检测值
      let res = this.uav_pInF(recArr[i], type);
      let entityRectangle = new Cesium.Entity({
        name: 'gas-entityRectangle',
        show: false,
        boundaries: recArr[i],
        rectangle: {
          coordinates: Cesium.Rectangle.fromDegrees(recArr[i][0], recArr[i][1], recArr[i][2], recArr[i][3]), // west, south, east, north
          material: Cesium.Color.fromCssColorString(res.color).withAlpha(0.6),
          // material: Cesium.Color.YELLOW,
          // material: Cesium.Color.fromRandom(),
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          disableDepthTestDistance: 99000000,
        },
        position: new Cesium.Cartesian3.fromDegrees(midPosLon, midPosLat),
        label: {
          text: String(res.value),
          scale: 0.3,
          fillColor: Cesium.Color.BLACK,
        }
      })
      await gasDataSource.entities.add(entityRectangle);
    }
    return true;
  },

  // 气体检测
  gasDetection () {
    // console.log(gasData, 'gasData');
    // 添加飞机模型，设置视角
    window.viewer.scene.logarithmicDepthBuffer = false;
    gasModelEntity1 = new Cesium.Entity({
      id: "gas_flying_drone",
      position: new Cesium.Cartesian3.fromDegrees(gasData[0].lon, gasData[0].lat, gasData[0].alt + 550),
      model: {
        // uri: '/cesium/model/drone_costum.glb',
        uri: '/cesium/model/Q20/Q20_withAnimation_20230926_01.gltf',
        scale: 60,
      },
      label: {
        text: new Cesium.CallbackProperty((time, result) => {
          return gasLabel;
        }),
        pixelOffset: new Cesium.Cartesian2(0, -100),
        scale: 0.6,
        fillColor: Cesium.Color.fromCssColorString('#FFAA25'),

      },
      viewFrom: new Cesium.Cartesian3(0, 0, 1000.0) // 偏移量--设置视角高度
      // viewFrom: new Cesium.Cartesian3.fromDegrees(gasData[0].lon, gasData[0].lat, gasData[0].alt + 550), // 偏移量--设置视角高度
    });
    window.viewer.entities.add(gasModelEntity1);
    window.viewer.trackedEntity = gasModelEntity1;
    // window.viewer.flyTo(gasModelEntity1, {
    //   offset: new Cesium.HeadingPitchRange(0, Cesium.Math.toRadians(-90), 10)
    // });

  },

  // 鼠标选择要监测的类型
  async gasSelectType (type) {
    // 获取监测类型数据的最大和最小
    let len = gasData.length;
    let temp = [];
    for (let i = 0; i < len; i++) {
      temp.push(gasData[i][type]);
    }
    gasMaxValue = _.max(temp);
    gasMinValue = _.min(temp);
    let valueList = [];
    let c = (gasMaxValue - gasMinValue) / 10;
    for (let i = 0; i < 11; i++) {
      let d = gasMinValue + c * i;
      d = parseFloat(d).toFixed(3);
      console.log(d);
      valueList.push(d);
    }
    Bus.$emit('gas_detection_value_list', valueList);


    // 初始化加载数据
    return await this.uav_initGas(type);
  },

  // 开始飞行
  gasBeginFly (type) {
    // let pix = '', pixu = '';
    // if (type == 'temperature') {
    //   pix = '温度'; pixu = '°C';
    // } else if (type == 'humidity') {
    //   pix = '湿度'; pixu = '%';
    // } else if (type == 'airPressure') {
    //   pix = '气压'; pixu = 'Pa';
    // } else if (type == 'CO') {
    //   pix = 'CO'; pixu = 'ppm';
    // } else if (type == 'NO2') {
    //   pix = 'NO2'; pixu = 'ppm';
    // } else if (type == 'O3+NO2') {
    //   pix = 'O3+NO2'; pixu = 'ppm';
    // } else if (type == 'PM1.0') {
    //   pix = 'PM1.0'; pixu = 'μg/m3';
    // } else if (type == 'PM2.5') {
    //   pix = 'PM2.5'; pixu = 'μg/m3';
    // } else if (type == 'PM10') {
    //   pix = 'PM10'; pixu = 'μg/m3';
    // }
    // 设置飞行参数
    // console.log('设置飞行参数');
    let len = gasData.length;
    let start = Cesium.JulianDate.fromDate(new Date(gasData[0].time));
    let stop = Cesium.JulianDate.fromDate(new Date(gasData[len - 1].time));
    window.viewer.clock.startTime = start.clone();
    window.viewer.clock.stopTime = stop.clone();
    window.viewer.clock.currentTime = start.clone();
    // window.viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP; //Loop at the end
    window.viewer.clock.clockRange = Cesium.ClockRange.CLAMPED; //Loop at the end
    window.viewer.clock.multiplier = 5;
    let position = this.uav_computeCirclularFlight(gasData, 1, start);
    let availability = new Cesium.TimeIntervalCollection([
      new Cesium.TimeInterval({
        start: start,
        stop: stop,
      }),
    ]);

    // let labelTextCall = new Cesium.CallbackProperty(function (time, result) {
    //   return gasLabel;
    // });

    gasModelEntity1.availability = availability;
    gasModelEntity1.position = position;
    // gasModelEntity1.label.text = labelTextCall;

    window.viewer.trackedEntity = gasModelEntity1;
    let _this = this;
    let enLen = gasDataSource.entities.values.length;

    // 处于飞行状态
    gasStopFlag = false;

    window.viewer.clock.onTick.addEventListener((e) => {
      // 如果处于停止飞行
      if (gasStopFlag) {
        return;
      }
      let b = dayjs(Cesium.JulianDate.toDate(e._currentTime)).format('YYYY-MM-DD HH:mm:ss');
      if (gasData[len - 1].time == b) {
        // alert('飞完了');
        // 如果飞行完毕则隐藏网格
        // console.log('飞完了');
        gasStopFlag = true;
        // 清除网格
        this.uav_gasClearF();
        // console.log('清除网格');
        // 清除模型
        this.uav_gasClearM();
        // console.log('清除模型');
        // 停止飞行参数
        this.uav_gasStopFly();
        // console.log('停止飞行参数');
        window.viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(gasData[0].lon, gasData[0].lat, gasData[0].alt + 550),
          orientation: {
            heading: Cesium.Math.toRadians(0),
            pitch: Cesium.Math.toRadians(-90),
            roll: 0
          },
          duration: 3,
          endTransform: Cesium.Matrix4.IDENTITY
        });
        // 清除热力图
        this.uav_clearHeatmap();
        // console.log('视角归位');
        Bus.$emit('gas_flying_over');
        return;
        // 
        // let enLen = gasDataSource.entities.values.length;
        // let gasEns = gasDataSource.entities.values;
        // for (let i = 0; i < enLen; i++) {
        //   gasEns[i].show = false;
        // }
      }
      // 当前时刻位置
      let pos = position.getValue(e._currentTime);
      // 当前无人机的经纬度
      let lonlatObj = _this.uav_cartesianToLonLat(pos);
      let gasEnsArr = gasDataSource.entities.values;
      for (let i = 0; i < enLen; i++) {
        let _boundaries = gasEnsArr[i]._boundaries;
        let minLon = _boundaries[0];
        let minLat = _boundaries[1];
        let maxLon = _boundaries[2];
        let maxLat = _boundaries[3];
        let cLon = lonlatObj.lon;
        let cLat = lonlatObj.lat;
        if (cLon >= minLon && cLon <= maxLon && cLat >= minLat && cLat <= maxLat) {
          // console.log(gasEnsArr[i], '进来了');
          gasEnsArr[i].show = true;
        }
      }
    })
  },

  computeCirclularFlight (pointArr, flySecond, start) {
    let property = new Cesium.SampledPositionProperty();
    for (let i = 0; i < pointArr.length; i++) {
      let time = Cesium.JulianDate.fromDate(new Date(pointArr[i].time));
      let pos = new Cesium.Cartesian3.fromDegrees(pointArr[i].lon, pointArr[i].lat, pointArr[i].alt + 500)
      property.addSample(time, pos);
    }
    return property;
  },

  // 初始化的时候就加载矩形框
  async initAddRectangle (type) {

    window.viewer.scene.globe.depthTestAgainstTerrain = true; //默认为false

    return true;
    console.log(type, 'typetypetypetypetype');
    // await gasDataSource.entities.removeAll();
    await window.viewer.dataSources.remove(gasDataSource);
    await window.viewer.dataSources.add(gasDataSource);
    let len = gasData.length;
    let temp = [];
    for (let i = 0; i < len; i++) {
      temp.push(gasData[i][type]);
    }
    let max = _.max(temp);
    let min = _.min(temp);
    for (let i = 0; i < len - 1; i++) {
      let item1 = gasData[i];
      let item2 = gasData[i + 1];
      console.log(item1);
      console.log(item2);
      let id = new Date(item1.time).getTime();
      let west = item1.lon, east = item2.lon, north = item1.lat, south = item2.lat;
      if (item1.lon > item2.lon) {
        east = item1.lon;
        west = item2.lon;
      } else {
        east = item2.lon;
        west = item1.lon;
      }
      if (item1.lat > item2.lat) {
        north = item1.lat;
        south = item2.lat;
      } else {
        north = item2.lat;
        south = item1.lat;
      }
      let alpha = (item1[type] - min) / (max - min);
      // console.log(alpha, 'alphaalphaalphaalphaalphaalpha');
      let color = '';
      if (alpha >= 0 && alpha < 0.1) {
        color = '#519244';
      } else if (alpha >= 0.1 && alpha < 0.2) {
        color = '#658c44';
      } else if (alpha >= 0.2 && alpha < 0.3) {
        color = '#788643';
      } else if (alpha >= 0.3 && alpha < 0.4) {
        color = '#8b8043';
      } else if (alpha >= 0.4 && alpha < 0.5) {
        color = '#9f7a43';
      } else if (alpha >= 0.5 && alpha < 0.6) {
        color = '#b27443';
      } else if (alpha >= 0.6 && alpha < 0.7) {
        color = '#c56e43';
      } else if (alpha >= 0.7 && alpha < 0.8) {
        color = '#d86842';
      } else if (alpha >= 0.8 && alpha < 0.9) {
        color = '#ec6242';
      } else if (alpha >= 0.9 && alpha <= 1.0) {
        color = '#ff5c42';
      }

      // console.log(alpha, 'alphaalphaalphaalphaalpha');
      // console.log(color, 'colorcolorcolorcolorcolorcolor');

      // console.log(turf, 'turf');
      console.log(item1[type], 'item1[type]');


      let point1 = turf.point([item1.lon, item1.lat]);
      let point2 = turf.point([item2.lon, item2.lat]);
      // // 1 计算两点中心点
      let midpoint = turf.midpoint(point1, point2);
      // 2 计算中心点，第一个点，另外一个对角线点
      // let options = { units: 'miles' };
      // let distance = turf.distance(point1, point2, options);
      // console.log(midpoint, 'midpoint');
      // console.log(distance, '千米');
      // console.log(point1, 'point1');
      // let line = turf.lineString([point2.geometry.coordinates, midpoint.geometry.coordinates, point1.geometry.coordinates]);
      // let along = turf.along(line, 100, options);
      // console.log(along, 'disssssssssssssssssssssssssssssssssss');
      // console.log(point2, 'disssssssssssssssssssssssssssssssssss');
      // let m1 = along.geometry.coordinates;
      // let m2 = midpoint.geometry.coordinates;
      // let arr = [
      //   m1[0], m1[1],
      //   m1[0], m2[1],
      //   m2[0], m2[1],
      //   m2[0], m1[1],
      //   m1[0], m1[1],
      // ];
      let m = midpoint.geometry.coordinates;

      let entityRectangle = new Cesium.Entity({
        id: id,
        name: 'gas-entityRectangle',
        show: true,
        rectangle: {
          coordinates: Cesium.Rectangle.fromDegrees(west, south, east, north), // west, south, east, north
          // material: Cesium.Color.fromCssColorString("#21A3F1").withAlpha(alpha),
          material: Cesium.Color.fromCssColorString(color),
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          disableDepthTestDistance: 99000000,

          // height: 400,
          // material: 'static/image/hzw.png'
          // outline: true, // height must be set for outline to display
          // outlineColor: Cesium.Color.RED,
          // extrudedHeight: 10000,
        },
        // polygon: {
        //   hierarchy: {
        //     // positions: Cesium.Cartesian3.fromDegreesArray([
        //     //   item1.lon, item1.lat,
        //     //   item1.lon, item2.lat,
        //     //   item2.lon, item2.lat,
        //     //   item2.lon, item1.lat,
        //     //   item1.lon, item1.lat
        //     // ]),
        //     positions: Cesium.Cartesian3.fromDegreesArray(arr),
        //   },
        //   material: Cesium.Color.YELLOWGREEN,
        // },
        // position: new Cesium.Cartesian3.fromDegrees(item1.lon, item1.lat),
        position: new Cesium.Cartesian3.fromDegrees(m[0], m[1]),
        // point: {
        //   pixelSize: 2,
        //   color: Cesium.Color.RED,
        // },
        // label: {
        //   text: String(item1[type]),
        //   scale: 0.5,
        //   // color: Cesium.Color.BLACK,
        //   fillColor: Cesium.Color.BLACK,
        //   // style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        //   showBackground: false,
        //   font: '4px sans-serif',
        //   // backgroundColor: Cesium.Color(255, 255, 0),
        //   scaleByDistance: new Cesium.NearFarScalar(1.5e2, 2.0, 1.5e7, 0.5),
        //   translucencyByDistance: new Cesium.NearFarScalar(
        //     1.5e2,
        //     1.0,
        //     1.5e8,
        //     0.0
        //   ),
        //   heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        //   disableDepthTestDistance: 99000000,

        // }
        // polyline: {
        //   positions: new Cesium.Cartesian3.fromDegreesArray([item1.lon, item1.lat, item2.lon, item2.lat]),
        //   material: Cesium.Color.fromCssColorString("#21A3F1"),
        //   width: 1
        // }
      })
      // window.viewer.entities.add(entityRectangle);
      await gasDataSource.entities.add(entityRectangle);
    }
    return true;
  },
  // 停止飞行
  gasStopFly () {
    // console.log(this.uav_gasFlyTitle, '停止飞行监控');
    // gasDataSource.entities.removeAll();
    // this.uav_gasClearF();
    gasStopFlag = true;
    // window.viewer.entities.remove(gasModelEntity1);
    let start = Cesium.JulianDate.fromDate(new Date());
    window.viewer.clock.startTime = start.clone();
    // 设置时钟当前时间
    window.viewer.clock.currentTime = start.clone();
    // 时间速率，数字越大时间过的越快
    window.viewer.clock.multiplier = 1;
    // 解除锁定
    window.viewer.trackedEntity = undefined;

    // 视角重置，重置到芜湖
    // window.viewer.camera.flyTo({
    //   destination: Cesium.Cartesian3.fromDegrees(gasData[0].lon, gasData[0].lat, gasData[0].alt + 550),
    //   orientation: {
    //     heading: Cesium.Math.toRadians(0),
    //     pitch: Cesium.Math.toRadians(-90),
    //     roll: 0
    //   },
    //   duration: 3,
    //   endTransform: Cesium.Matrix4.IDENTITY
    // });

    window.viewer.camera.flyTo({
      // destination: Cesium.Cartesian3.fromDegrees(120.218241, 33.165767, 21812.321476599747),
      destination: Cesium.Cartesian3.fromDegrees(118.22073, 29.50576, 151812.321476599747),
      orientation: {
        heading: 0.006918732929549698,
        pitch: -0.6928665494487145,
        roll: 7.638334409421077e-14,
      },
      time: 5,
    });

  },
  // 隐藏方块
  gasHideF () {
    let enLen = gasDataSource.entities.values.length;
    let gasEns = gasDataSource.entities.values;
    for (let i = 0; i < enLen; i++) {
      console.log(gasEns[i], 'gasEns[i]');
      gasEns[i].show = false;
    }
  },
  // 清除方块
  gasClearF () {
    if (gasDataSource) {
      // gasDataSource.entities.removeAll();
      // window.viewer.dataSources.remove(gasDataSource);
      // console.log(gasDataSource, 'gasDataSourcegasDataSourcegasDataSourcegasDataSource');
      let a = gasDataSource.entities.values;
      for (let i = 0; i < a.length; i++) {
        gasDataSource.entities.remove(a[i]);
        i--;
      }
    }
  },
  // 清除模型
  gasClearM () {
    if (gasModelEntity1) {
      window.viewer.entities.remove(gasModelEntity1);
    }
  },
  // 热力图绘制
  gasHeatMap (type) {
    this.uav_clearHeatmap();
    // 获取经纬度四至
    let len = gasData.length;
    let lonArr = [], latArr = [], valueArr = [];
    let bzPoint = [];
    for (let i = 0; i < len; i++) {
      lonArr.push(gasData[i].lon);
      latArr.push(gasData[i].lat);
      valueArr.push(gasData[i][type]);
      let temp = {};
      temp.x = gasData[i].lon;
      temp.y = gasData[i].lat;
      temp.value = gasData[i].value;
      bzPoint.push(temp);
    }
    let latMin = _.min(latArr);
    let latMax = _.max(latArr);
    let lonMin = _.min(lonArr);
    let lonMax = _.max(lonArr);
    let valueMin = _.min(valueArr);
    let valueMax = _.max(valueArr);
    let bounds = {
      west: lonMin,
      south: latMin,
      east: lonMax,
      north: latMax
    };

    heatMap = CesiumHeatmap.create(
      window.viewer, // 视图层
      bounds, // 矩形坐标
      { // heatmap相应参数
        backgroundColor: "rgba(0,0,0,0)",
        radius: 10,
        maxOpacity: .85,
        minOpacity: 0.2,
        blur: .80,
        gradient: {
          // 0.25: "rgb(0,0,255)", 0.55: "rgb(0,255,0)", 0.85: "red", 1.0: "rgb(255,0,0)"
          // 0.1: "#1a00e6",
          // 0.2: "#3300cc",
          // 0.3: "#4d00b3",
          // 0.4: "#660099",
          // 0.5: "#800080",
          // 0.6: "#990066",
          // 0.7: "#b3004d",
          // 0.8: "#cc0033",
          // 0.9: "#e6001a",
          // 1.0: "#ff0000"

          // 0.1: "#ff1a00",
          // 0.2: "#ff3300",
          // 0.3: "#ff4d00",
          // 0.4: "#ff6600",
          // 0.5: "#ff8000",
          // 0.6: "#ff9900",
          // 0.7: "#ffb300",
          // 0.8: "#ffcc00",
          // 0.9: "#ffe600",
          // 1.0: "#ffff00",

          0.1: "#5c9f4a",
          0.2: "#6c964a",
          0.3: "#7d8e49",
          0.4: "#8d8549",
          0.5: "#9e7d48",
          0.6: "#af7447",
          0.7: "#bf6c47",
          0.8: "#d06346",
          0.9: "#e05b46",
          1.0: "#f15245",

        }
      }
    );
    heatMap.setWGS84Data(valueMin, valueMax, bzPoint);
  },
  // 清除热力图
  clearHeatmap () {
    // console.log(heatMap, 'heatMapheatMapheatMap');
    if (heatMap) {
      heatMap._layer.show = false;
      window.viewer.scene.forceRender();
    }
  },
  claerGas () {
    this.uav_gasClearM();
    this.uav_gasClearF();
    this.uav_clearHeatmap();
    this.uav_gasStopFly();
  },
  // 生成网格
  grid (lonMin, lonMax, latMin, latMax, disX, disY) {
    let recArr = [];
    let lineLon = turf.lineString([[lonMin, latMax], [lonMax, latMax]]);
    let lineLat = turf.lineString([[lonMin, latMin], [lonMin, latMax]]);
    let options = { units: 'meters' };
    let lonArr = [];
    let latArr = [];
    let currentLon = 0;
    let currentLat = 0;
    let i = 0;
    while (lonMax > currentLon) {
      let eDisX = i * disX;
      let along = turf.along(lineLon, eDisX, options);
      currentLon = along.geometry.coordinates[0];
      lonArr.push(currentLon);
      i++;
    }
    i = 0;
    while (latMax > currentLat) {
      let eDisY = i * disY;
      let along = turf.along(lineLat, eDisY, options);
      currentLat = along.geometry.coordinates[1];
      latArr.push(currentLat);
      i++;
    }
    let lonLen = lonArr.length;
    let latLen = latArr.length;
    for (let i = 0; i < lonLen - 1; i++) {
      for (let j = 0; j < latLen - 1; j++) {
        let m = [lonArr[i], latArr[j]];
        let n = [lonArr[i + 1], latArr[j + 1]];
        let arr = [m[0], m[1], n[0], n[1]];
        recArr.push(arr);
      }
    }
    return recArr;
  },
  // Cartesian3 转 经纬度
  cartesianToLonLat (c3) {
    let cartographic = window.viewer.scene.globe.ellipsoid.cartesianToCartographic(c3);
    let lon = Cesium.Math.toDegrees(cartographic.longitude);
    let lat = Cesium.Math.toDegrees(cartographic.latitude);
    let height = cartographic.height;
    return { lon: lon, lat: lat, height: height };
  },
  // 求出落在方框中的点
  pInF (_boundaries, type) {
    let sItem = [];
    for (let i = 0; i < gasDataLen; i++) {
      let item = gasData[i];
      let cLon = item.lon;
      let cLat = item.lat;
      let minLon = _boundaries[0];
      let minLat = _boundaries[1];
      let maxLon = _boundaries[2];
      let maxLat = _boundaries[3];
      if (cLon >= minLon && cLon <= maxLon && cLat >= minLat && cLat <= maxLat) {
        // console.log('落！');
        sItem.push(gasData[i][type]);
      }
    }
    let value = parseFloat(_.mean(sItem)).toFixed(5);
    if (isNaN(value)) {
      value = gasMinValue;
    }

    // console.log(sItem, 'sItem');
    // console.log(value, 'value');
    // console.log(gasMaxValue, 'gasMinValuegasMinValuegasMinValuegasMinValuegasMinValue');
    // console.log(gasMinValue);
    let a = Math.abs(parseFloat((value - gasMinValue) / (gasMaxValue - gasMinValue)));
    let colorFlag = String(a.toFixed(1));
    // console.log(colorFlag, 'colorFlagcolorFlagcolorFlagcolorFlag');
    // console.log(gasColorObj[colorFlag], 'gasColorObj[colorFlag]gasColorObj[colorFlag]gasColorObj[colorFlag]gasColorObj[col');
    return { value: String(value), color: gasColorObj[colorFlag] }
  }
}