import { values } from "@antv/util";

export default {
    list: [],

    online: {},
    LsdomFlag: false,
    model: {},
    msg_list:[],
    // 提示信息
    NoticeFlag: false,
    TakeOverFlag:false,
    NoticeInfoFlag:false,
    NoticeData: {
        deviceHardId:"",
        leftText: "取消",
        rightText: "确认",
        title: '接管确认',
        content:""
    },
    control: {
        device: null,
        data: null,
        battery: null,
        mount: null,
        mounts: [],
        info: [],
        channelData: {}
    }, provide() {
        return {
            nest_this: this,
        }
    },
    mode: [
        { id: 1, data: "MANUAL", label: "手动模式" },
        { id: 2, data: "ALTITUDE", label: "定高模式" },
        { id: 3, data: "POSITION", label: "GPS模式" },
        { id: 4, data: "AUTO_MISSION", label: "航线模式" },
        { id: 5, data: "ACRO", label: "特技模式" },
        { id: 6, data: "OFF_BOARD", label: "版外模式" },
        { id: 7, data: "STABILIZED", label: "自稳模式" },
        { id: 8, data: "HOLD", label: "定点模式" },
        { id: 9, data: "LAND", label: "降落模式" },
        { id: 10, data: "TAKE_OFF", label: "起飞模式" },
        { id: 11, data: "AUTO_RTL", label: "返航模式" },
        { id: 12, data: "AUTO_FOLLOW_TARGET", label: "自动跟随模式" },
    ],
    videos: [
    ]
}