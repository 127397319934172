<!-- 飞控新版鹰视1027 -->
<template>
  <div class="nest_controlBox">
    <PositionBall class="pa ding" :uav-data="uavData" />
    <ymfz :channelData="data"> </ymfz>
    <img class="img2" src="@/assets/img/ball2.png" />
      <img class="img3" src="@/assets/img/ball2.png" />
    <div class="w900 h78 nest_control">
      <!-- <div class="kong"></div> -->
      <InfoDJ class="w550 ml80 h78" v-if="device.goodsName == 'M300'" :uav-data="uavData" :wsShow="wsShow"></InfoDJ>
      <Info class="w550 ml80 h78" v-else :uav-data="uavData" :wsShow="wsShow"></Info>
      <div class="xian" v-if="!device.goodsName == 'M300'"></div>
      <battery v-if="uavBattery" :uav-battery="uavBattery" :device="device"></battery>
    </div>

    <div class="bizhang">
      <obstacle :uav-data="uavData"></obstacle>
    </div>
  </div>
</template>
<script>
import PositionBall from "./components/ball/index";
import Info from "./components/info/index";
import InfoDJ from "./components/infoDJ/index";
import battery from "./components/battery/index";
import ymfz from "./components/ymfz";
import obstacle from "./components/obstacle";

export default {
  data() {
    return {};
  },
  props: {
    uavData: {
      type: Object,
      default: () => ({})
    },
    uavBattery: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Object,
      default: () => ({})
    },
    device: {
      type: Object,
      default: () => ({})
    },
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      }
    }
  },
  components: { PositionBall, Info, InfoDJ, battery, ymfz, obstacle },
  mounted(){
    // console.log(this.device,"infodevice");
  },
  computed: {
    props_obj() {
      let { data } = this;
    }
  }
};
</script>
<style scoped lang="scss">
.nest_controlBox {
  width: 860px;
  height: 200px;
  position: absolute;
  display: flex;
  justify-content: start;
  justify-content: center;
  align-items: center;
  left: calc(50% - 450px);
  bottom: -35px;
  z-index: 1;
  pointer-events: none;
  padding: 0 0px 0px 20px;
  .nest_control {
    display: flex;
    align-items: center;
    background: rgba(9, 32, 87, 0.7);
  }
  .img2{
    position: absolute;
      left: -36px;
      top: calc(35%);
      width: 8px;
      height: 9px;
      z-index: 9999;
      transform: rotate(-75deg);
  }
  .img3{
    position: absolute;
      left: -40px;
      top: calc(34%);
      width: 8px;
      height: 9px;
      z-index: 9999;
      transform: rotate(115deg);
  }
}
.xian {
  width: 0.3px;
  height: 72px;
  border: 0.5px solid;
  border-image: linear-gradient(
      180deg,
      rgba(112, 218, 249, 0),
      rgba(112, 218, 249, 1),
      rgba(112, 218, 249, 0)
    )
    2 2;
  margin: 10px 10px 0 0;
}
.bizhang {
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: -50px;
  top: 14px;
}
.kong {
  width: 40px;
}
.ding{
  left: -31px;
}
</style>