<template>
  <div class="page-observe-fckernel-uav">
    <!-- <Header @TakeOverFlag="showTakeOver" :wsShow="wsShow" @control_ws="uav_control_ws" :uav-data="uav.control.data"
      :isSelectFlag="flag" :device="uav.control.device" @changeF="$emit('changeF')" @fn="uav_mqtt_fn"></Header> -->
    <Header class="cp" :title="headerTitle" />
    <!-- @nest_chenge="nestchange" -->
    <!-- 日志信息 -->

    <BrightTail v-if="uav.control.device" :wsShow="wsShow" :device="uav.control.device" :uavData='uav.control.data'
      @fn="(data, type) => uav_mqtt_fn(data, type)" @fun="(data) => uav_send_ws(data)" @take_photo="take_photoFn"
      @face_car="face_car" @brightTailshowDialog='brightTailshowDialog' @draw_photo="set_take_photoFn"
      ref="BrightTailRef" />

    <Logger @exit="loggerFlag = false" v-if="loggerFlag && !isAirwayEdit" :list="uav.msg_list"
      @clear-msg="uav.msg_list = []"></Logger>

    <!-- 无人机列表和任务列表面板 -->

    <div class="nsetLeftBox" :class="{ collapse: collapseFlag }" v-show="nsetLeftBoxShowFlag">


      <!-- <img @click="collapseFlagfn" v-if="collapseFlag" style="transform: rotate(180deg)"
        src="~@/assets/images/observe/fckernel/hs.png" class="icon-collapse nsetLeftBox_btn" />

      <img @click="collapseFlagfn" v-else src="~@/assets/images/observe/fckernel/collapse.png"
        class="icon-collapse nsetLeftBox_btn" /> -->

      <div class="uav-list-header_jt" v-if="listPage == 1">

        <span :class="listType == 'wrj' ? 'uav-list-header__text_left' : 'deflault_uav-list-header__text'" class=" cp"
          @click="listType = 'wrj'">无人机列表</span>

        <span :class="listType == 'task' ? 'uav-list-header__text_right' : 'deflault_uav-list-header__text'" class=" cp"
          @click="listType = 'task'">任务列表</span>

      </div>

      <div class="uav-list-header_jt" v-else>
        <span :class="listType == 'wrj' ? 'deflault_uav-list-header__text' : 'uav-list-header__text_left'" class=" cp"
          @click="listType = 'wrj'">无人机列表</span>
      </div>

      <!-- <div class="uav-list-header" v-else>
        <img class="uav-list-header__icon" src="~@/assets/images/uav_list_header.png" />
        <span class="uav-list-header__text cp" @click="listType = 'wrj'">无人机列表</span>
      </div> -->

      <div v-show="listType == 'wrj'">

        <div class="uav-search">

          <el-input class="uav-search__input" clearable placeholder="请输入无人机名称/机构名称" v-model="uavSearchContent"
            v-on:keyup.enter.native="onUavSearch">
            <!-- <i slot="suffix" class="el-input__icon el-icon-search" style="color: rgba(123, 181, 213, 1);"></i> -->
          </el-input>

          <el-button class="uav-search__btn" icon="el-icon-search" @click="onUavSearch"></el-button>

        </div>

        <UavList :class="uav.TakeOverFlag ? 'top0' : ''" @exit="flag = false" v-if="flag" @videoChange="videoChange"
          :containerStyle="{
            width: '400PX',
            height: '275px',
          }" :list="uav.list" @fn="uav_fn" @refresh="onUavSearch" />
      </div>

      <div class="history-list" v-if="listType == 'task'">
        <HistoryList />
      </div>

      <div></div>

    </div>

    <component v-if="uav.control.mount" :is="uav.control.mount.component"
      :PoliceKeyCode="uav.control.device && uav.control.device.deviceHardId" @directive="uav_mount_directive"
      @close="uav_change_mount" :deviceid="uav.control.device" />

    <Control :device="uav.control.device" :wsShow="wsShow" :data="uav.control.channelData"
      v-if="ControlFlag || (uav.control.data && !isAirwayEdit)" :uav-battery="uav.control.battery"
      :uav-data="uav.control.data" @fn="(data, type) => uav_mqtt_fn(data, type)" @fun="(data) => uav_send_ws(data)">
    </Control>

    <AirwayEdit :flag="false" @add="airway_add" v-if="isAirwayEdit" :isShow="false" @quit="AirwayQuit"></AirwayEdit>
    <!-- -->

    <div class="videoBox">
      <!-- v-if="videoFlag && uav.videos.length > 0 && !isAirwayEdit" -->
      <!-- uav_move_directive -->
      <FloatPlayer @directiveFn="uav_move_directive" @imgUrl="getimgUrl" :device="uav.control.device"
        v-for="(video, index) in uav.videos" :uav-data="uav.control.data" @videoItemFn="videoItemFn(index)"
        @close="uav_hide_video" @fn="uav_mqtt_fn" :key="video.id" :data="video" :class="`_${index}`"
        :videoItem="videoItem" :index="index" ref="FloatPlayer" />
      <!-- <FloatPlayer
        @directive="uav_move_directive"
        :device="uav.control.device"
        v-for="(video, index) in uav.videos"
        :uav-data="uav.control.data"
        @videoItemFn="videoItemFn(index)"
        @close="uav_hide_video"
        :key="video.id"
        :data="video"
        :class="`_${index}`"
        :videoItem="videoItem"
        :index="index"
      />-->
    </div>
    <!-- @airway-display="uav_airway"
    @airway-view="airway_view"-->
    <!-- <TaskList :device="uav.control.device" :taskstyle="collapseFlag" @Lsdom="Lsdom" @taskType="taskTypeFn"
      @iconShow="iconShow = true" ref="TaskListRef" @fn="(data, type) => uav_mqtt_fn(data, type)"
      @fun="(data) => uav_send_ws(data)" @craeteRoute="CraeteRoute" v-if="controlMenuFlag"
      @createTaskClick="createTaskClick"></TaskList>
       -->
    <ControlMenu ref="ControlMenu" :wsShow="wsShow" :taskstyle="collapseFlag" @imgUrl="getimgUrl" @clearId="clearIdFn"
      :wrjType="uav.wrjType" :device="uav.control.device" @Lsdom="Lsdom" :mount="uav.control.mounts"
      @uav-location="uav_show_location(uav.control.device)" @airway-display="uav_show_airway(uav.control.device)"
      :taskType="taskType" :uav-mounts="uav.mounts" v-if="uav.control.device" @yxrz="LoggerFn" :iconShow="iconShow"
      @fn="(data, type) => uav_mqtt_fn(data, type)" @fun="(data) => uav_send_ws(data)" @changeTask="onChangeTask">
    </ControlMenu>
    <!-- 请求控制权限 -->
    <Notice @close="uav.NoticeFlag = false" @handle="uav_jursdiction" class @qzjg="qzjg" @exit="uav.NoticeFlag = false"
      v-if="uav.NoticeFlag" :leftText="uav.NoticeData.leftText" :rightText="uav.NoticeData.rightText"
      :content="uav.NoticeData.content" :title="uav.NoticeData.title"></Notice>
    <!-- 接管记录 -->
    <TakeOver @exit="uav.TakeOverFlag = false" v-if="uav.TakeOverFlag"></TakeOver>
    <!-- <Temp v-if="uav.control.device" :device="uav.control.device" :uav-data="uav.control.data"></Temp> -->
    <VideoTomap v-if="VideoTomapFlag && videoFlag && uav.videos.length > 0"></VideoTomap>
    <el-dialog width="40%" :visible.sync="progressT">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="jindu"></el-progress>
    </el-dialog>
    <FaceAI v-if="faceAiShow" :uavId="device.deviceHardId" @closeface="faceAiShow = false" />
    <CarAI v-if="carAiShow" :uavId="device.deviceHardId" :list="carList" @closecar="carAiShow = false" />
    <div class="imgshowurl" v-if="imgshow">
      <div @click="imgshow = false" class="close">关闭</div>
      <img :src="imgshowurl" />
    </div>
    <!-- 指引提示框 -->
    <el-dialog :visible.sync="deviceHardVisible" width="30%">
      <div class="cf f18 fw700 ml20 pt20 tc">
        {{ BrightTailList.itemLIist && BrightTailList.itemLIist.name }}
      </div>
      <div class="m30">
        <el-radio-group v-model="radio">
          <el-radio :label="item" class="mt10 cf" v-for="(item, index) in BrightTailList.alertList.alertList"
            :key="index">
            {{ item.taskGuideDetailsAlertContent }} <br /></el-radio>
        </el-radio-group>
      </div>
      <div v-show="BrightTailList.alertList.alertList &&
        BrightTailList.alertList.alertList.length > 0
        " class="cp w150 h40 cf f18 tc lh40" style="background: #129c9c; margin: 0 auto" @click="brightTailnext">
        下一步
      </div>
    </el-dialog>

    <!-- 侧边栏 -->
    <SideBar class="side-bar" :sceneFlag="sceneFlag" @scene-app-change="sceneAppChange"></SideBar>

    <!-- ----------------------- 气体检测开始 ----------------------- -->
    <div class="gas" v-if="uav.gas">

      <div class="title">监测类型</div>
      <div class="type">
        <span class="item" @click="gasSelectType('temperature')"
          :class="[gasSelectTypeFlag == 'temperature' ? 'item-selected' : '']">温度</span>
        <span class="item" @click="gasSelectType('humidity')"
          :class="[gasSelectTypeFlag == 'humidity' ? 'item-selected' : '']">湿度</span>
        <span class="item" @click="gasSelectType('airPressure')"
          :class="[gasSelectTypeFlag == 'airPressure' ? 'item-selected' : '']">气压</span>
        <!-- <span class="item" @click="gasSelectType('VOC')"
          :class="[gasSelectTypeFlag == 'VOC' ? 'item-selected' : '']">VOC</span>
        <span class="item" @click="gasSelectType('SO2')"
          :class="[gasSelectTypeFlag == 'SO2' ? 'item-selected' : '']">SO2</span> -->
        <span class="item" @click="gasSelectType('CO')"
          :class="[gasSelectTypeFlag == 'CO' ? 'item-selected' : '']">CO</span>
        <span class="item" @click="gasSelectType('NO2')"
          :class="[gasSelectTypeFlag == 'NO2' ? 'item-selected' : '']">NO2</span>
        <span class="item" @click="gasSelectType('O3+NO2')"
          :class="[gasSelectTypeFlag == 'O3+NO2' ? 'item-selected' : '']">O3+NO2</span>
        <span class="item" @click="gasSelectType('PM1.0')"
          :class="[gasSelectTypeFlag == 'PM1.0' ? 'item-selected' : '']">PM1.0</span>
        <span class="item" @click="gasSelectType('PM2.5')"
          :class="[gasSelectTypeFlag == 'PM2.5' ? 'item-selected' : '']">PM2.5</span>
        <span class="item" @click="gasSelectType('PM10')"
          :class="[gasSelectTypeFlag == 'PM10' ? 'item-selected' : '']">PM10</span>

      </div>

      <div class="title">操作</div>

      <div class="action">
        <span class="item" @click="gasBeginFly('play')"
          :class="[gasTypeSelectedFlag ? 'item-selected item-selected-play' : 'forbid']">{{ gasFlyTitle }}</span>
        <span class="item" @click="gasHeatMap('play')"
          :class="[gasTypeSelectedFlag ? 'item-selected item-selected-play' : 'forbid']">热力图</span>
      </div>
    </div>
    <div class="gasLegend" v-if="uav.gas && gasDetectionValueList.length != 0">
      <span class="item" v-for="(item, index) in gasDetectionValueList" :key="index">{{ item }}</span>
    </div>
    <!-- ----------------------- 气体检测结束 ----------------------- -->

    <!-- ----------------------- 灾情对比开始 ----------------------- -->
    <!-- <Disaster v-if="uav.disaster"></Disaster> -->
    <!-- 安徽省阜阳市王家坝周边洪涝灾害监测图 -->
    <div class="disaster-title" v-if="uav.disaster">洪涝灾害多期影像对比监测</div>
    <div id="disasterSlider" v-if="uav.disaster"></div>
    <!-- GF-1卫星2023年6月25日影像 -->
    <div class="disaster-left-map-lable" v-if="uav.disaster">GF-1卫星2023年3月25日影像</div>
    <div class="disaster-right-map-lable" v-if="uav.disaster">遥感卫星2023年7月20日12时影像</div>
    <!-- ----------------------- 灾情对比结束 ----------------------- -->



  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FaceAI from "@/components/observe/uav/faceai";
import CarAI from "@/components/observe/uav/carai";
//历史任务

import HistoryList from "./components/historyList"; //亮尾4

import { saveAs } from "file-saver";
import BrightTail from "@/components/accident/brightTail/index"; //亮尾4
import UavList from "@/components/accident/uav/list";
import Mount from "@/components/observe/mount/MMC_Gimbal_Z40S";
// 视频播放器
import FloatPlayer from "@/components/accident/uav/float-playerfk";
// import FloatPlayer from "@/components/observe/fckernel/uav/float-playerfk";
import AirwayEdit from "@/components/observe/fckernel/common/airway-edit";

import data from "./data";
import methods from "./methods";
// import Header from "@/components/accident/header/index";
import Header from "@/components/eagleStrike/header/index1027";
// 下面的油门图标
import Control from "@/components/accident/uav/control/index.vue";
// 任务库
import TaskList from "@/components/accident/uav/taskList";
// 下面一键任务的小图标
import ControlMenu from "@/components/accident/uav/control/controlMenu";
import Notice from "@/components/accident/uav/control/components/notice";
import Logger from "@/components/accident/uav/control/logger/index";
import TakeOver from "@/components/accident/uav/control/components/takeOver";
import Temp from "@/components/accident/uav/temp";
import VideoTomap from "@/components/accident/uav/control/videoTomap";

// 引入侧边栏
import SideBar from '@/components/accident/header/sideBar.vue';

// 引用bus
import Bus from "@/assets/ligature.js";
import API from "@/api";
import { Utils } from "@/lib/cesium";
export default {
  data () {
    return {
      ...data,
      headerTitle: '场景应用',
      listType: "wrj",//wrj列表 /task 任务列表
      listPage: null,
      imgshow: false,//拍照图片展示
      imgshowurl: null,
      faceAiShow: false,
      carAiShow: false,
      videoFlag: true,
      // 头部状态是否为展示状态
      flag: true,
      controlMenuFlag: false,
      loggerFlag: false,
      // 运行监测
      powerFlag: true,
      // 创建航线窗口
      isAirwayEdit: false,
      // 创建任务
      CreateTaskFlag: false,
      // 左下角切换视频
      VideoTomapFlag: false,
      // 下方展示数据面板
      ControlFlag: false,
      iconShow: false,
      taskType: "",
      videoItem: 0,
      collapseFlag: false,
      linevisible: false,
      takeOff: false,
      uavId: null, //无人机id
      progressT: false, //进度条弹框
      jindu: 0, //进度条进度
      uavSearchContent: "", //无人机搜索内容
      wsShow: false,//判断是走飞控中心链路还是地面站链路,
      // 指引弹窗
      deviceHardVisible: false,
      radio: 0,
      BrightTailList: {
        taskId: null,
        alertList: { alertList: {} },
        itemLIist: null

      },
      // 气体检测
      gasSelectTypeFlag: '',
      gasTypeSelectedFlag: false,
      gasFlyTitle: '开始飞行监控',
      gasFlyFlag: false,
      gasDetectionValueList: [],
      sceneFlag: 0, //侧边栏显示标志 0 1 2 。。。7
      nsetLeftBoxShowFlag: true,
    };
  },
  provide () {
    return {
      uav_This: this,
      findList: search => {
        if (search) {
          this.uav_list(search);
        } else {
          this.uav_list(search);
          // this.uav_mountList(data.name);
        }
      },
      uav_mqtt_fn: this.uav_mqtt_fn,
      uav: this.uav
    };
  },
  components: {
    BrightTail,
    UavList,
    Mount,
    FloatPlayer,
    Header,
    Control,
    TaskList,
    ControlMenu,
    AirwayEdit,
    Notice,
    TakeOver,
    Temp,
    Logger,
    VideoTomap,
    FaceAI,
    CarAI,
    HistoryList,
    SideBar,
  },
  watch: {
    data: function (vla, old) {
    }
  },
  computed: {
    ...mapGetters(["user_info"]),
    uav_mounts () {
      let { mounts } = this.uav.control;
      return mounts
        .map(item => {
          let find_item = this.mount.list.find(m => m.name === item.gimbalName);
          return find_item || undefined;
        })
        .filter(item => item);
    }
  },
  created () {
    this.listPage = this.$route.query.page
    Bus.$on("remove_model", () => {
      // 删除飞控 无人机 模型和航线
      // 搜索关键字：删除飞控模型
      console.log('删除飞控 无人机 模型和航线...accident');
      this.uav_remove_model(this.uav.control.device);
    });
    // 等待航线上传成功在执行
    // 判断是否以一键任务
    Bus.$on("off_take_yj", () => {
      this.takeOff = true;
    });

  },
  methods: {
    ...methods,
    // 指引列表弹窗选项
    brightTailshowDialog (alertList, taskId, itemLIist) {
      this.BrightTailList = {
        taskId: taskId,
        alertList: alertList,
        itemLIist: itemLIist
      }
      this.deviceHardVisible = true
    },
    // 指引列表弹窗提交
    async brightTailnext () {
      let res = await API.ACCIDENT.createGuideAction({
        guideId: this.BrightTailList.itemLIist.guideId,
        taskId: this.BrightTailList.taskId,//this.accident.taskId
        taskGuideActionType: 2,
        taskGuideDeatilsId: this.BrightTailList.alertList.taskGuideDetailsId,
        taskGuideActionStatus: 1,
        taskGuideDetailsAlertId: this.radio.taskGuideDetailsAlertId,
        startSort: this.BrightTailList.alertList.guideSort,
        endSort: this.radio.jumpDetailsSort
      })
      if (res) {
        // 更新列表zhuangt
        this.deviceHardVisible = false
        this.radio = ""
        this.$refs.BrightTailRef.getList()
      }
    },
    getimgUrl (val) {
      console.log(val, 'val');
      this.imgshowurl = val
      this.imgshow = true
    },
    async qzjg () {
      let res = await API.DEVICE.forceTakeOver({
        deviceHardId: this.uav.NoticeData.deviceHardId
      });
      if (res.code == 200) {
        this.$message.success(res.msg);
        this.uav.NoticeFlag = false;
      }
    },
    fly_take_off () {
      // 等待航线上传成功在执行
      this.$refs.TaskListRef.upload_complete();
      // Bus.$emit("take_off");
    },
    getYd () {
      this.controlMenuFlag = !this.controlMenuFlag;
      if (this.collapseFlag == true) {
        // 修改飞控 无人机 左边'任务库'的位置
        Bus.$emit("ydh", false);
      } else {
        Bus.$emit("ydh", true);
      }
    },
    collapseFlagfn () {
      this.collapseFlag = !this.collapseFlag;
      if (this.collapseFlag == true) {
        // 修改飞控 无人机 左边'任务库'的位置
        Bus.$emit("ydh", false);
      } else {
        Bus.$emit("ydh", true);
      }
    },
    nsetLeftBoxShowHandle (v) {
      console.log(v, 'vbvnnnnn');
      this.nsetLeftBoxShowFlag = v;
    },
    Lsdom (item) {
      this.$emit("Lsdom", item);
    },
    clearIdFn () {
      if (this.$refs.TaskListRef) {
        this.$refs.TaskListRef.close();
        try {
          let deviceHardId = this.uav.control.device.deviceHardId;
          this.uav_hide_airway(this.uav.control.device);
          this.uav.online[deviceHardId].positions = [this.uav.online[deviceHardId].position];
        } catch (error) { }
      }
    },
    videoItemFn (index) {
      this.videoItem = index;
      this.$store.commit("mapmanage/SET_MAP_IS_VIDEOITEM", index);
    },
    LoggerFn () {
      this.loggerFlag = !this.loggerFlag;
      // this.collapseFlag = false;
    },
    videoChange () {
      this.videoFlag = !this.videoFlag;
    },
    nestchange (flag) {
      this.flag = flag;
      this.controlMenuFlag = false;
      this.uav.TakeOverFlag = false;
    },
    showTakeOver () {
      this.uav.TakeOverFlag = !this.uav.TakeOverFlag;
      if (this.uav.TakeOverFlag) {
        this.collapseFlag = true;
      }
    },
    // 关闭航线创建列表
    AirwayQuit () {
      this.isAirwayEdit = false;
      // 运行监测关闭
      this.powerFlag = true;
      this.Videoflag = true;
      this.ControlFlag = true;
      this.collapseFlag = false;
    },
    taskTypeFn (item) {
      this.taskType = item;
    },
    // 创建航线
    CraeteRoute () {
      this.isAirwayEdit = true;
      // // 运行监测关闭
      this.powerFlag = false;
      this.ControlFlag = false;
      this.collapseFlag = true;
    },
    createTaskClick () {
      this.CreateTaskFlag = !this.CreateTaskFlag;
      this.controlMenuFlag = false;
    },
    async checkUseOperateFn (device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device });
      if (res.code == 201) {
        this.$message.warning(res.msg);
        return false;
      } else {
        return true;
      }
    },
    /**
     * 无人机搜索
     */
    onUavSearch () {
      this.uav_list({
        search: this.uavSearchContent
      });
    },
    //点击任务按钮
    onChangeTask (open) {
      if (open === undefined) {
        this.controlMenuFlag = !this.controlMenuFlag;
      } else {
        this.controlMenuFlag = open;
      }
    },
    take_photoFn () {
      // console.log(this.$refs.FloatPlayer.player,'this.$refs.FloatPlayer');
      if (this.$refs.FloatPlayer) {
        console.log(this.$refs.FloatPlayer, ' this.$refs.FloatPlayer');
        this.$refs.FloatPlayer[0].photo();
      }
    },
    face_car () {
      if (this.$refs.FloatPlayer) {
        // this.$refs.FloatPlayer[0].handle("b");
        this.$refs.FloatPlayer[0].handle("a");
      }
    },
    set_take_photoFn () {
      if (this.$refs.ControlMenu && this.$refs.ControlMenu.$refs.Traffic) {
        this.$refs.ControlMenu.$refs.Traffic.onActiveApp();
      }
    },
    //地图飞入
    cesium_layer_change_mode (fn, modes) {

      // 定位到芜湖
      Utils.initLocation2WUHU();
      return;

      window.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          120.218241,
          33.165767,
          21812.321476599747
        ),
        orientation: {
          heading: 0.006918732929549698,
          pitch: -0.6928665494487145,
          roll: 7.638334409421077e-14
        },
        time: 5
      });
    },

    // 点击检测类型按钮
    async gasSelectType (type) {
      // 清除热力图
      this.uav_clearHeatmap();
      // 清除方块和模型
      this.uav_gasClearF();
      this.uav_gasClearM();

      // 重新加载模型
      this.uav_gasDetection();
      // 设置检测类型
      this.gasSelectTypeFlag = type;
      // 先加载网格数据，隐藏
      let res = await this.uav_gasSelectType(type);

      console.log(res, 'res');

      // 点击了类型选择按钮
      setTimeout(() => {
        this.gasTypeSelectedFlag = true;
      }, 1000)

    },
    // 点击开始飞行监控按钮
    gasBeginFly () {
      // this.uav_hide_airway
      if (!this.gasTypeSelectedFlag) {
        this.$message({
          type: 'warning',
          message: '请先选择监测类型'
        });
        return;
      }
      this.gasFlyFlag = !this.gasFlyFlag;
      if (this.gasFlyFlag) {
        this.gasFlyTitle = '停止飞行监控';
        this.uav_gasBeginFly(this.gasSelectTypeFlag);
        console.log('开始飞行监控');
      } else {
        this.gasFlyTitle = '开始飞行监控';
        // console.log('清理！！！');
        // 1 清除图上方块，清除模型
        this.uav_gasClearF();
        this.uav_gasClearM();
        // 2 操作按钮不可用
        this.gasTypeSelectedFlag = false;
        // 3 清除选择
        this.gasSelectTypeFlag = "";
        this.uav_gasStopFly();
        // 4 清除热力图
        this.uav_clearHeatmap();
        // 图例隐藏重置
        this.gasDetectionValueList = [];
      }
    },
    gasHeatMap () {
      // 清
      this.uav_clearHeatmap();
      this.uav_gasHeatMap(this.gasSelectTypeFlag);
    },
    /**
     *@Description: 点击侧边栏事件
     *@Date: 2023-10-24 17:12:38
     *@Params1: 
     *@Return1: 
    */
    sceneAppChange (v) {
      this.sceneFlag = v;
    }
  },
  mounted () {
    const params = new URLSearchParams(this.$route.query)
    let m1 = params.get('page')
    // let m2 = m1;
    // if (m1 == 0) {
    //   m2 = 1;
    // }
    // if (m1 == 1) {
    //   m2 = 0;
    // }
    // console.log(this.sceneFlag, 'this.sceneFlag');
    // console.log(m1, 'mmmmmmmm1');
    Bus.$emit('item-change-flag', m1);


    // console.log('MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM');

    this.uav_list();
    this.mqtt_init();
    this.uav_init_ws();


    Bus.$on('gas_flying_over', () => {
      // console.log('一次飞行结束！！！');
      // 检测类型重置
      this.gasSelectTypeFlag = '';
      this.gasTypeSelectedFlag = false;
      this.gasFlyTitle = '开始飞行监控';
      this.gasFlyFlag = false;
      this.gasDetectionValueList = [];
    });

    Bus.$on('gas_init', () => {
      // this.collapseFlag = true;
      // this.collapseFlagfn();
    });

    Bus.$on('gas_detection_value_list', (v) => {
      this.gasDetectionValueList = v;
    });

  },
  beforeDestroy () {
    this.uav.control.device = null;
    this.uav.control.device = null;
    this.uav.control.data = null;
    this.uav.control.battery = null;
    this.uav.control.mount = null;
    this.uav.control.mounts = [];
    this.uav.control.mounts = [];
    this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
      flag: false,
      type: "wrj"
    });
    this.uav.videos = [];
    if (this.mqtt.client) {
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
  },
  inject: ["g_cesium_layer"]
};
</script>

<style lang="scss" scoped>
.collapse {
  transform: translateX(-100%);
  transition: 0.3s;
}

.page-observe-fckernel-uav {
  .LsdomStyle {
    position: fixed;
    top: 50%;
    z-index: 50;
  }

  height: 100%;

  .leftBox {
    width: 508px;
    height: calc(100% - 58px);
    position: fixed;
    left: 0;
    top: 58px;
    background: rgba(0, 23, 79, 0.7);
    box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
      inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
    border-radius: 10px;
  }

  .videoBox {
    /* width: 382px; */
    // border: 1px solid #D8D8D8;
    position: absolute;
    top: 60px;
    padding-bottom: 10px;
  }

  .side-bar {
    position: absolute;
    top: 64px;
    left: 0;
    width: 80px;
    background: #0E3042;
    height: calc(100% - 64px);
  }
}

.nsetLeftBox {
  position: absolute;
  width: 460px;
  left: 80px;
  top: 64px;
  // height: 86vh;
  transition: 0.3s;
  z-index: 99;
  // background: rgba(9, 32, 87, 0.7);
  background-color: #0A293A;
  // border-radius: 10px 10px 0px 0px;
  height: calc(100% - 64px);

  width: 410px;
  // height: 54px;
  // background: #224D68;

  // border: 1px solid #70daf9;
  .nsetLeftBox_btn {
    position: absolute;
    cursor: pointer;
    right: -22px;
    top: 50%;
    margin-top: -90px;
    z-index: 99;
  }

  .history-list {
    width: 100%;
    height: 100%;
  }
}

.acitve {
  color: #70daf9;
}

.uav-search {
  // padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 7px;
  // margin-top: 14px;
  box-sizing: border-box;
  padding: 0 15px;

  width: 410px;
  height: 64px;
  background: #133E55;

  .uav-search__input ::v-deep {
    // width: 317px;
    // height: 32px;
    width: 349px;
    height: 40px;
    background: #0A293A;
    border-radius: 4px;
    // box-shadow: 0px 2px 4px 0px rgba(28, 94, 124, 0.5),
    //   inset 0px 0px 3px 0px #00b6ff;
    // border-radius: 4px;

    .el-input__icon {
      line-height: 32px;
    }

    input {
      // height: 32px;
      height: 40px;
      // background: #02173d;
      background-color: #0A293A;
      color: #43deff;
      border: 0px solid #315ec7;

      &::placeholder {
        font-size: 12px;
        color: #7bb5d5;
        color: #898A8E;
      }
    }
  }

  .uav-search__btn {
    // padding: 7px 25px 6px 26px;
    // // background: rgba(38, 71, 238, 0.71);
    // background: url("~@/assets/images/observe/twobg.png");
    // border-radius: 4px;
    // border: 1px solid;
    // font-size: 14px;
    // color: #fff;
    border: 0;
    color: #fff;
    width: 42px;
    height: 40px;
    background: #4E87AC;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.uav-list-header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 460px;
  height: 33px;
  background: linear-gradient(180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%);
  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #427dff;

  .uav-list-header__text {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background: linear-gradient(135deg,
        #f7b67d 38%,
        #f9eacb 58%,
        #f5d2a6 79%,
        #f59743 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .uav-list-header__icon {
    width: 26px;
    margin-left: 9px;
  }
}

.uav-list-header_jt {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 460px;
  height: 43px;
  width: 410px;
  height: 54px;
  background: #224D68;

  .uav-list-header__text_left {
    // width: calc(100% / 2);
    width: 30%;
    font-size: 16px;
    text-align: center;
    // font-family: YouSheBiaoTiHei;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #14faff;
    line-height: 43px;
    // background: linear-gradient(135deg,
    //     #f7b67d 38%,
    //     #f9eacb 58%,
    //     #f5d2a6 79%,
    //     #f59743 100%);

    // box-shadow: inset 0px 0px 10px 2px #3f9dff;
    // border-radius: 10px 0px 0px 0px;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .uav-list-header__text_right {
    width: calc(100% / 2);
    font-size: 16px;
    text-align: center;
    // font-family: YouSheBiaoTiHei;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #14faff;
    line-height: 43px;
    // background: linear-gradient(135deg,
    //     #f7b67d 38%,
    //     #f9eacb 58%,
    //     #f5d2a6 79%,
    //     #f59743 100%);

    // box-shadow: inset 0px 0px 10px 2px #3f9dff;
    // border-radius: 0px 10px 0px 0px;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .deflault_uav-list-header__text {
    width: 30%;
    font-size: 16px;
    // font-family: YouSheBiaoTiHei;
    font-family: PingFangSC-Regular, PingFang SC;
    cursor: pointer;
    // color: #14faff;
    color: #fff;
    text-align: center;
    line-height: 26px;
    // text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    // background: #fff;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .uav-list-header__icon {
    width: 26px;
    margin-left: 9px;
  }
}

.imgshowurl {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba($color: #000000, $alpha: 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  img {
    width: 1000px;
  }

  .close {
    color: #fff;
    margin-left: 1000px;
    margin-bottom: 16px;
    // position: absolute;
    // right: 10px;
    // top: 10px;
  }
}

.gas {
  position: absolute;
  top: 100px;
  right: 100px;
  width: 200px;
  // height: 100px;
  padding: 5px;
  box-sizing: border-box;
  height: auto;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  z-index: 100;

  .title {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }

  // cursor: pointer;
  .type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    // border: 1px solid yellow;
    border-radius: 4px;

    .item {
      width: 44%;
      height: 28px;
      margin: 5px;
      background-color: #315ec7;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
      font-size: 12px;
    }

    .item-selected {
      background-color: #f7b67d;
    }
  }

  .action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    // border: 1px solid yellow;
    border-radius: 4px;

    .item {
      width: 44%;
      height: 28px;
      margin: 5px;
      background-color: #315ec7;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
      font-size: 12px;
    }

    .forbid {
      cursor: no-drop;
      background-color: gray;
    }
  }

}

.gasLegend {
  position: absolute;
  height: 700px;
  width: 10px;
  // border: 1px solid yellow;
  left: 85px;
  top: 100px;
  z-index: 100;
  background: linear-gradient(to bottom, #5c9f4a, #F4F048 50%, #fa1b06);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 0px;
  box-sizing: border-box;
  color: #fff;

  .item {
    font-size: 12px;
    margin-left: 20px;
  }
}


#disasterSlider {
  position: absolute;
  left: calc(50vw - 50px);
  top: 85px;
  background-color: #d3d3d3;
  width: 5px;
  height: calc(100vh - 10px);
  z-index: 2;
}

#disasterSlider:hover {
  cursor: ew-resize;
}

.disaster-title {
  font-size: 55px;
  font-weight: 700;
  color: #333;
  font-family: "楷体";
  width: 100vw;
  text-align: center;
  line-height: 80px;
  background-color: #304576;
  color: #fff;
  height: 80px;
  position: absolute;
  top: 60px;
  z-index: 10;
}

.disaster-left-map-lable {
  position: absolute;
  z-index: 2;
  bottom: 30px;
  left: 40px;
  font-size: 28px;
  color: #333;
  text-shadow: 2px 2px #fff, -2px -2px #fff, -2px 2px #fff, 2px -2px #fff;
}

.disaster-right-map-lable {
  position: absolute;
  z-index: 2;
  bottom: 30px;
  right: 40px;
  font-size: 28px;
  color: #333;
  text-shadow: 2px 2px #fff, -2px -2px #fff, -2px 2px #fff, 2px -2px #fff;
}
</style>