<template>
  <div class="card-container">
    <div class="line line1">
      <div class="item">
        <div class="n">{{ data.task_title }}</div>
      </div>
    </div>

    <div class="line line2">
      <div class="item item1">
        <span>航线名称：{{ data.airlineTitle }}</span>
      </div>
      <div class="item item2">
        <span>任务类型：
          <span v-if="data.taskCateId == '1'" style="color: #fb4a2d">警情任务</span>
          <span v-else-if="data.taskCateId == '2'" style="color: #00d45c">常态任务</span>
          <span v-else style="color: #ffbd36">临时紧急任务</span>
        </span>
      </div>
    </div>

    <div class="line line3">
      <div class="item item1">
        <img src="@/assets/img/i1.png" alt="">
        <span>结束时间：{{ data.endTime }}</span>
      </div>
    </div>

    <div class="line line5">
      <el-tooltip effect="dark" content="下载" placement="top">
        <img src="@/assets/img/i9.png" :class="[imgFlag == 0 ? 'img-selected' : '']" alt=""
          @click="$emit('download', data)">
      </el-tooltip>

      <el-tooltip effect="dark" content="图片" placement="top">
        <img src="@/assets/img/i5.png" :class="[imgFlag == 4 ? 'img-selected' : '']" alt=""
          @click="$emit('imageShow', data)">
      </el-tooltip>

    </div>

  </div>
</template>

<script>
import { downloadData } from "@/utils/download";
import { mapGetters } from "vuex";
export default {
  // inject: [
  //   "flight_task_chage_view",
  //   "handle_video_Player",
  //   "handle_air_line_preview",
  //   "handle_location",
  //   "handle_image",
  //   "handle_histoty_track",
  //   "change_cate_id",
  // ],
  props: {
    data: {
      type: Object,
      default: {}
    },
    taskType: {
      type: String,
      default: '常态任务',
    }
  },
  computed: {
    ...mapGetters(["user_info"]),
    isJH () {
      return this.user_info && this.user_info.departmentId == 2;
    },
  },

  mounted () {
    console.log(this.data, 'DDDDDDDDDDDDDDDDDDDDDDD');
  },
  data () {
    return {
      imgFlag: 0,
    }
  },
  methods: {
    imgCilckHandle (v, item) {
      console.log(item, 'item');
      this.imgFlag = v;
      switch (v) {
        // 下载
        case 0:
          if (item.taskStatus != 1) {
            this.$message({
              type: 'info',
              message: '暂无下载数据'
            });
            return;
          }
          downloadData(
            `/hawksystemserver/export/normalcyDownload/${item.id}?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`,
            this.taskType
          );
          break;
        // 详情
        case 1:
          console.log('详情');
          break;
        // 定位
        case 2:
          this.handle_location(item, 2);
          break;
        // 航线预览
        case 3:
          this.handle_air_line_preview(item, 1);
          break;
        // 图片
        case 4:
          if (item.applyStatus == 1 &&
            item.papplyStatus == 1 &&
            item.executeStatus == 1) {
            console.log('图片');
            this.handle_image(item);
          } else {
            this.$message({
              type: 'info',
              message: '无历史图片'
            });
          }
          break;
        // 历时轨迹
        case 5:
          if (item.applyStatus == 1 &&
            item.papplyStatus == 1 &&
            item.executeStatus == 1) {
            console.log('历时轨迹');
            this.handle_histoty_track(item);
          } else {
            this.$message({
              type: 'info',
              message: '无历史轨迹'
            });
          }
          break;
        // 删除
        case 6:
          if (item.taskStatus != 3) {
            // this.$message({
            //   type: 'info',
            //   message: '无历史轨迹'
            // });
            return;
          }
          this.$emit('delete', item);
          break;
      }
    },
    // 下载航线
    downLoadAirLine () {

    }
  },
}
</script>

<style lang="scss" scoped>
.card-container {
  width: 100%;
  // height: 169px;
  height: 100%;
  background: #163B53;
  border-radius: 4px;
  padding: 0 0 0 0;
  box-sizing: border-box;

  .line {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .line1 {
    height: 20%;
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .n {
        // margin-left: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D5DBE0;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .line2 {
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // background-color: #274F69;

    .item {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D5DBE0;
      }
    }

    .item1 {
      justify-content: flex-end;
    }

    .item2 {
      justify-content: flex-start;
      margin-top: 8px;
    }
  }

  .line3 {
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .item {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 18px;
        height: 18px;
      }

      span {
        margin-left: 5px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(99, 130, 163, 1);
      }
    }

    .item2 {
      margin-top: 2px;
    }
  }


  .line5 {
    height: 20%;
    // background-color: rgb(200, 255, 0);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      // width: 34px;
      width: 28px;
      // height: 34px;
      height: 28px;
      // background-color: #3CE171;
      box-sizing: border-box;
      padding: 5px;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 10px;
    }

    .img-selected {
      // background: #274F69;
      border-radius: 4px;
    }
  }
}
</style>