<template>
  <div class="car dialog1027">
    <div class="dialog-header" v-interact>
        <div class="dialog-header__icon" />
        <div class="dialog-header__title">
            车辆识别
        </div>
        <div class="dialog-header_close" style="color:#fff" @click="()=>$emit('close')">关闭</div>
    </div>
    <div class="dialog-content">
      <template v-if="list.length > 0">
        <div v-for="(item, i) in list" :key="i" class="car-item">
          <div class="car-img">
            <img class="car-img__img" :src="imgUrl + item.imageUrl" />
            <div class="car-img__label">{{ item.plateNum || '暂无' }}</div>
          </div>
          <div class="car-form">
            <div>地点：{{ item.addr || item.findAddress || '暂无' }}</div>
            <div>时间：{{ item.findTime || '暂无' }}</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div style="text-align: center; width: 100%;">暂无数据</div>
      </template>
    </div>
  </div>
</template>

<script>
import API from '@/api';

export default {
  props: {
    uavId: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      imgUrl: process.env.VUE_APP_IMG_URL,
      list: []
    }
  },
  created(){
    this.getCarList()
  },
  methods: {
    async getCarList(){
      if(!this.uavId){
        this.$message.error('请选择无人机');
        return;
      }

      let res = await API.HOME.getflightvideoMsg({
        uavId: this.uavId
      })

      for(let i = 0; i < res.length; i++){
        let item = res[i];
        if(item.longi){
          let address = await API.MAP.AiRegeo({
            location: `${item.longi},${item.lati}`
          })
          item.addr = address.province.value + address.city.value + address.dist.value + address.road.roadname + address.poi;
        }
      }

      this.list = res || [];
    }
  }
}
</script>

<style lang="scss" scoped>
.car{
  width: 268px;
}

.dialog-content{
  padding: 20px 14px 12px;
  max-height: 461px;
  overflow: auto;
}

.car-img{
  position: relative;
  width: 100%;
  height: 135px;

  .car-img__img {
    width: 100%;
    height: 100%;
  }

  .car-img__label {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #aee9ff;
    font-family: MicrosoftYaHeiUI;
  }
}

.car-form{
  padding: 10px 0 0;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #fff;

  >div {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.car-item{
  margin-bottom: 24px;
}
</style>