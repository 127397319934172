<template>
  <div class="cpt-nest-logger">
    <div class="title-box" v-interact>
      <div class="title pl20">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="font">运行监控日志</div>
      </div>
      <div style="display: flex">
        <div class="icon-box" @click="$emit('clear-msg')">
          <span class="iconfont icon-qingchushuju"></span>
          <!-- <span class="icon-text pr20">清除数据</span> -->
        </div>
        <div class="cf ml20 mr10 c70d" @click="$emit('exit')">关闭</div>
      </div>
    </div>
    <div class="ctx-box">
      <div class="head mt7">
          <div class="text-box pl100">
            <div class="type">消息等级</div>
          </div>
            <span class="text">消息内容</span>
        </div>
      <div class="list-box">
        <div class="item-box mt7" v-for="(item,index) in list" :key="index">
          <div
            class="text-box pl100"
            :style="item.grade && item.grade == 99 || item.grade && item.grade <= 2 ? 'color: #FA3140': item.grade && item.grade > 2 && item.grade < 5 ? 'color: #F9AB09': item.grade && item.grade == 5 ? 'color: #8E1DB8':''"
          >
            <div v-if="item.grade" class="type-box">
              <span class="type f16">{{ item.grade }}</span>
            </div>
            <div
              v-else
              class="type-box"
              :class="{ emerg: item.type === 1, ordinary: item.type === 8 }"
            >
              <span class="type">AUTO</span>
            </div>
            <span
              class="text"
              :style="item.grade && item.grade == 99 || item.grade && item.grade <= 2 ? 'color: #FA3140': item.grade && item.grade > 2 && item.grade < 5 ? 'color: #F9AB09': item.grade && item.grade == 5 ? 'color: #8E1DB8':''"
            >{{ item.text }}</span>
          </div>
        </div>
      </div>
      <div class="wih100 mt100 h60 xb"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handle_clear() {
      this.list = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-nest-logger {
  position: fixed;
  width: 630px;
  height: 250px;
  top: 21%;
  left: 32%;
  z-index: 5;
  display: flex;
  overflow-y: auto;
  background: rgba(9, 32, 87, 0.7);
  // border: 1px solid #70DAF9;
  border-radius: 10px 10px 0 0;
  flex-direction: column;

  .title-box {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .title {
      display: flex;
      align-items: center;
      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .icon-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .iconfont {
        font-size: 18px;
        color: #00ffff;
        margin-right: 4px;
      }

      .icon-text {
        font-family: MicrosoftYaHeiUI;
        font-size: 14px;
        color: #ccedff;
        font-weight: 400;
      }
    }
  }

  .ctx-box {
    flex: 1;
    overflow: hidden;
    .head{
        display: flex;
        align-items: center;
        .text-box {
          .type {
            color: #bacfea;
            font-size: 12px;
          }
        }
        .text {
          margin-left: 20px;
            font-size: 12px;
            color: #bacfea;
          }
      }
    .list-box {
      height: 90%;
      overflow: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      .item-box {
        display: flex;
        align-items: center;
        color: #fff;

        .text-box {
          display: flex;
          align-items: center;

          .type-box {
            width: 38px;
            height: 20px;
            transform-origin: center;
            transform: scale(0.6);
            padding: 2px 6px;
            border-radius: 30px;
            // text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            &.ordinary {
              background-color: #298ad3;
            }

            &.emerg {
              background-color: #ff3c3c;
            }

            .type {
              text-align: center;
              font-size: 10px;
            }
          }

          .text {
            font-size: 12px;
            color: #bacfea;
          }
        }
      }
    }
  }

  .xb {
    transform: rotate(-180deg);
    background: rgba(1, 10, 46, 0.63);
    border-radius: 10px 10px 0 0;
  }
}
.c70d {
  color: #70daf9;
}
</style>
