<template>
  <div class="w142 h48 mountBox">
    <div v-if="mounts.length > 0" class="list">
      <div class="mount-item pr mt6" v-for="(item, index) in mount" :key="index">
        <div class="icon-box" @click="fn(item)">
          <img class="cp dib w30 h30" :src="item.icon" />
        </div>
      </div>
    </div>
    <template v-if="selected_mount">
      <!-- fixed -->
      <component :moveType="moveType" :selected_mount="selected_mount" :device="device" :is="selected_mount.component"
        @directive="handle_directive" @take_photo="take_photo" @record="record" @close="selected_mount = null"
        :PoliceKeyCode="uav_This.uav.control.device.deviceHardId" :payload="
  selected_mount && uavMountsPayload
    ? uavMountsPayload[selected_mount.name]
    : []
        " />
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      selected_mount: {},
      moveType: "wrj"
    };
  },
  props: {
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      }
    },
    iconShow: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: Boolean,
      default: ""
    },
    uavMounts: {
      type: Array,
      default: () => []
    },
    uavMountsPayload: {
      type: Array,
      default: () => []
    },

    mount: {
      type: Array,
      default: () => []
    },
    device: {
      type: Object,
      default: () => ({})
    }
  },
  inject: ["uav_This"],
  methods: {
    fn(item) {
      // let data = {
      //   "gimbalName": "PagerP1",
      //   "mountId": 27,
      //   "mountType": 1051,
      //   "payload": [
      //     165,
      //     79,
      //     49,
      //     85,
      //     170,
      //     220,
      //     172,
      //     64,
      //     2,
      //     0,
      //     19,
      //     221,
      //     24,
      //     63,
      //     71,
      //     178,
      //     24,
      //     56,
      //     0,
      //     1,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     215,
      //     255,
      //     1,
      //     0,
      //     255,
      //     36,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     13,
      //     152,
      //     22,
      //     178,
      //     0,
      //     10,
      //     26,
      //     134
      //   ],
      //   "widgetHeight": 505,
      //   "widgetWidth": 500,
      //   "title": "MMC_Gimbal_P3",
      //   "icon": require("@/assets/images/observe/015.png"),
      //   "name": "MMC_Gimbal_P3",
      //   "type": [
      //     1,
      //     2,
      //     3,
      //     4,
      //     5,
      //     6,
      //     8
      //   ],
      //   component:() => import("@/components/observe/mount/MMC_Gimbal_Z20")
      // }
      this.selected_mount = item;
      this.$store.commit("fckernel/SET_SELECT_MOUNT", item);
    },
    handle_directive(buffer) {
      let { selected_mount } = this;
      if (selected_mount) {
        if (!this.wsShow) {
          this.$emit(
            "fn",
            {
              type: 514,
              data: {
                mountId: selected_mount.mountId,
                payload: buffer
              }
            },
            "wrj"
          );
        } else {
          let buff = buffer.join(",");
          let data = {
            type: 200,
            systemCode: "mmc",
            state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
            username: this.user_info.username,
            height: 0,
            idlingTime: 0,
            data: {
              cmdControlType: 7000, //520
              cmdFunction: null,
              cmdState: null,
              cmdValue: null,
              cmdBuffer: buff,
              cmdName: this.selected_mount.name
            },
            deviceHardId: this.device.deviceHardId
          };
          this.$emit("fun", data);
        }
      }
    },
    take_photo(data){
      if(!this.taskId || this.taskId == "") return this.$message.error("暂无任务！")
      this.$emit("fn",data)
    },
    record(data){
      if(!this.taskId  || this.taskId == "") return this.$message.error("暂无任务！")
      this.$emit("fn",data)
    }
  },
  beforeDestroy() {
    this.$store.commit("device/SET_MOVE_DATA", {});
  },
  computed: {
    ...mapGetters(["user_info"]),
    mounts() {
      let { uavMounts, mount } = this;
      console.log(uavMounts, "uavMounts");
      console.log(mount, "mount");
      // if (uavMounts && uavMounts.length) {
      //     let mounts = [];
      //     uavMounts.forEach((item) => {
      //         let m = mount.list.find((i) => i.name === item.gimbalName);
      //         if (m) {
      //             m.mountId = item.mountId;
      //             mounts.push(m);
      //         }
      //     });
      //     console.log(mounts);
      return mount;
      // }
      setTimeout(() => {
        this.tips = "暂无挂载";
      }, 5000);
      return [];
    }
  },
  created() {
    this.tips = "正在识别使用中的挂载....";
  }
};
</script>
<style scoped lang="scss">
.mountBox {
  background: rgba(9, 32, 87, 0.7);
  border: 1px solid #70daf9;
  position: absolute;
  top: -150px;
  right: 20px;

  .list {
    display: flex;
    justify-content: space-around;

    .mount-item {
      width: 30px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #70daf9;
    }
  }
}
</style>
