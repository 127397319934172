<template>
  <div class="slide-bar-container">
    <div class="item item1" v-for="(item, index) in dataList" :key="index" @click="click(index, item)"
      :class="flag == index ? 'selected' : ''">
      <img :src="item.imgUrl" alt="" class="img" :class="index == dataList.length - 2 ? 'img-last' : ''">
      <span>{{ item.name }}</span>
    </div>
    <!-- <div class="item item2" @click="click(1)" :class="flag == 1 ? 'selected' : ''">
      <img src="@/assets/img/fly-task.png" alt="">
      <span>飞行任务</span>
    </div>
    <div class="item item3" @click="click(2)" :class="flag == 2 ? 'selected' : ''">
      <img src="@/assets/img/air-line.png" alt="">
      <span>航空航线</span>
    </div>
    <div class="item item4" @click="click(3)" :class="flag == 3 ? 'selected' : ''">
      <img src="@/assets/img/real-time.png" alt="">
      <span>实时调度</span>
    </div> -->
  </div>
</template>

<script>
import Bus from "@/assets/ligature.js";
export default {
  props: {
    // flag: 
    sceneFlag: {
      type: Number,
      default: 0,
    }
  },
  data () {
    return {
      flag: 0,
      dataList: [
        { id: 1, name: '应急', imgUrl: require('@/assets/img/i114.png'), path: "/accident?page=0", },
        { id: 2, name: '交通', imgUrl: require('@/assets/img/i113.png'), path: "/accident?page=1", },
        { id: 3, name: '食药环', imgUrl: require('@/assets/img/i109.png'), path: "/accident?page=2", },
        { id: 4, name: '网安', imgUrl: require('@/assets/img/i110.png'), path: "/accident?page=3", },
        { id: 5, name: '水利', imgUrl: require('@/assets/img/i111.png'), path: "/accident?page=4", },
        { id: 6, name: '技侦', imgUrl: require('@/assets/img/i112.png'), path: "/accident?page=5", },
        { id: 7, name: "气体检测", imgUrl: require('@/assets/img/i140.png'), title: "气体检测", path: "/accident?page=6", type: "gas" },
        { id: 8, name: "灾情对比", imgUrl: require('@/assets/img/i141.png'), title: "灾情对比", path: "/accident?page=7", type: "disaster" },
        { id: 9, name: "全景图", imgUrl: require('@/assets/img/i142.png'), title: "全景图", path: "/sphere", type: "disaster" },
      ]
    }
  },
  mounted () {
    Bus.$on('item-change-flag', v => {
      // console.log(v, 'vvvvvvvvvvvvvvvv');
      this.flag = v;
    });
  },
  methods: {
    click (flag, item) {
      // console.log(flag);
      // console.log(item, 'item');
      // this.flag = flag;
      if (item && item.path) {
        // window.open("#" + item.path, "_blank");
        window.open("#" + item.path, "_self");
        this.$router.go(0);
        Bus.$emit('scene-app-change', flag);
        // this.$router.push({ path: item.path });
      }
      // switch (flag) {
      //   case 0:
      //     this.$emit('handleSelected', 0);
      //     break;
      //   case 1:
      //     this.$emit('handleSelected', 1);
      //     break;
      //   case 2:
      //     this.$emit('handleSelected', 2);
      //     break;
      //   case 3:
      //     this.$emit('handleSelected', 3);
      //     break;
      // }
    }
  },
}
</script>

<style lang="scss" scoped>
.slide-bar-container {
  width: 100%;
  height: 100%;

  .item {
    width: 82px;
    height: 81px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    cursor: pointer;

    span {
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      margin-top: 6px;
    }

    .img {
      width: 30px;
      height: 30px;
    }

    .img-last {
      width: 22px;
      height: 22px;
    }


  }

  .item:hover {
    background: #2B72EA;
  }

  .selected {
    background: #2B72EA;
  }
}
</style>