<template>
  <div class="videoTomapBox">
    <div class="item cf tc cp">
      <SymbolIcon icon="qiehuanshijiao" />
    </div>
    <div class="videoTomapBox_right">
      <el-tooltip content="视频背景模式" placement="bottom">
        <div class="ml10 itemList cp mt2" @click="changeSj">
          <SymbolIcon v-show="flag" icon="suoyoushijiao" />
          <SymbolIcon v-show="!flag" icon="diyishijiao" />
        </div>
      </el-tooltip>
      <!-- <div class=" itemList ml20 cp mr10 mt2" @click="$emit('showVideo')">
                <img class="  dib w32 h23 mt4" src="~@/assets/images/observe/fckernel/nest/changeVideo.png" alt="">
            </div> -->
    </div>
  </div>
</template>
<script>
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";
// 引用bus
import Bus from "@/assets/ligature.js";
export default {
  components: { SymbolIcon },
  data() {
    return {
      flag: false,
    };
  },
  methods: {
    changeSj() {
      this.flag = !this.flag;
      Bus.$emit('uav_flag',this.flag)
      if (this.flag) {
        this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
          flag: true,
          type: "wrj",
        });
      } else {
        this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
          flag: false,
          type: "wrj",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.videoTomapBox {
  position: absolute;
  left: 20px;
  bottom: 10px;
  width: 150px;
  display: flex;
  justify-content: flex-start;

  .item {
    margin-top: 10px;
    padding: 5px;
    background: rgba(9, 32, 87, 0.7);
    border-radius: 50%;
    border: 2px solid #fff;
  }

  .item:hover {
    transition: 0.3s;
    transform: rotate(180deg);
  }

  .videoTomapBox_right {
    min-width: 56px;
    display: none;
    background: rgba(9, 32, 87, 0.7);
    border: 1px solid #43deff;

    margin-top: 10px;
    padding: 3px;
    justify-content: flex-start;
    background: rgba(9, 32, 87, 0.7);

    .itemList {
      background: #000000;
      border-radius: 4px;
      border: 1px solid #eee;
    }
  }
}

.videoTomapBox:hover {
  transition: 1s;

  .videoTomapBox_right {
    display: flex;
  }
}
</style>