<!-- 飞控 自动 手动 -->
<template>
  <div class="controlMenuBox">
    <div class="left-bar" :class="{ collapse: taskstyle }">
      <!-- <div class="left-bar-item item" @click="closeAI()">
        <img class="left-bar-item__icon" src="~@/assets/images/nest/task.svg" />
        <div class="left-bar-item__text">任务</div>
      </div>
      <div class="left-bar-item item" @click=" openAIList = !openAIList; $emit('changeTask', false);">
        <img class="left-bar-item__icon" src="~@/assets/images/observe/ai.png" />
        <div class="left-bar-item__text">智能识别</div>
      </div> -->
      <div class="ai-list" :class="{ active: openAIList }" @click.stop>
        <div class="left-bar-item item" @click="switchAI(0)">
          <img class="left-bar-item__icon" src="~@/assets/images/observe/faceAI2.png" />
          <div class="left-bar-item__text">人脸识别</div>
        </div>
        <Face v-if="openFace" class="ai-dialog" :uavId="uavId" @close="openFace = false" />
        <div class="left-bar-item item" @click="switchAI(1)">
          <img class="left-bar-item__icon" src="~@/assets/images/observe/carAI2.png" />
          <div class="left-bar-item__text">车辆识别</div>
        </div>
        <Car v-if="openPlate" class="ai-dialog" :uavId="uavId" @close="openPlate = false" />
        <div class="left-bar-item item" @click="switchAI(2)">
          <img class="left-bar-item__icon" src="~@/assets/images/observe/traffic.png" />
          <div class="left-bar-item__text">交通指引</div>
        </div>
        <div class="left-bar-item item" @click="switchAI(3)">
          <img class="left-bar-item__icon" src="~@/assets/images/observe/ksjm.png" />
          <div class="left-bar-item__text">快速建模</div>
        </div>
        <Jm v-if="jmflag" :device="device" class="jm-dialog" @close="jmflag = false"></Jm>
        <Traffic ref="Traffic" v-if="openTrafiic" class="ai-dialog" :uavId="uavId" @close="openTrafiic = false" />
      </div>
    </div>

    <!-- 一键任务 返航 安全降落 手动 -->
    <!--  v-show="taskType != 'itemB'" -->
    <div class="bottom_center">
      <div class="w48 h48 item mb3 cf tc cp one" @click="handClick(0)">
        <SymbolIcon v-if="yyrw == false" icon="yijianrenwu" />
        <img v-else src="@/assets/images/font/yyrwh.gif" alt />
        <span class="dib f8">一键任务</span>
      </div>
      <!--  v-show="iconShow" -->
      <div class="w48 h48 item mb3 cf tc cp one" @click="handClick(1)">
        <SymbolIcon v-if="yjfh == false" icon="yijianfanhang2" />
        <img v-else src="@/assets/images/font/yyfhh.gif" alt />
        <span class="dib f8">一键返航</span>
      </div>
      <!--     v-show="iconShow" -->
      <div class="w48 h48 item mb3 cf tc cp one" @click="end">
        <SymbolIcon icon="renwujieshu1" />
        <span class="dib f8">任务结束</span>
      </div>
      <div v-if="!wsShow">
        <div v-if="!unlockType" class="w48 h48 item mb3 cf tc cp one"
          :class="!unlockType && iStatusValue == 15 ? 'sdActive' : 'sdDefault'">
          <div @click="changeType">
            <SymbolIcon v-if="sd == false" icon="shoudong" />
            <img v-else src="@/assets/images/font/zdh.gif" alt />
            <span class="dib f8">手动</span>
          </div>
        </div>
        <div v-else class="w48 h48 item mb3 cf tc cp one">
          <div @click="unlock">
            <!-- <SymbolIcon icon="shoudong" /> -->
            <SymbolIcon v-if="zd == false" icon="zidong" />
            <img v-else src="@/assets/images/font/zdh.gif" alt />
            <span class="dib f8">自动 </span>
          </div>
        </div>
      </div>
      <!-- device.isPush =99 代表大疆 -->
      <div class="w48 h48 item mb3 cf tc cp one" v-if="wsShow && device.goodsName == 'M300'">
        <div @click="changDj(0)" v-if="typeDj">
          <SymbolIcon icon="shoudong" />
          <span class="dib f8">手动</span>
        </div>
        <div v-else @click="changDj(1)">
          <SymbolIcon icon="zidong" />
          <span class="dib f8">自动</span>
        </div>
      </div>
    </div>
    <!-- 挂载-警灯-无人机  -->
    <div class="right_top">
      <div class="pf" style="top: 13%">
        <div class="w48 h48 item mb3 cf tc cp" :class="MountFlag ? 'selectItem' : ''" @click="mountFn">
          <SymbolIcon icon="guazai1" />
          <span class="dib f8">挂载</span>
        </div>
        <div class="w48 h48 item mb3 cf tc cp" :class="!controlTypeFlag && controlListFlag ? 'selectItem' : ''"
          @click="controlListFlagFn('wrj')">
          <SymbolIcon icon="wurenji2" />
          <span class="dib f8">无人机</span>
        </div>
        <div v-if="!wsShow" class="w48 h48 item mb3 cf tc cp" :class="police ? 'selectItem' : ''" @click="policeShow">
          <div>
            <svg width="22px" height="23px" viewBox="0 0 22 23" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>警示灯</title>
              <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="1-视频1" transform="translate(-1875.000000, -375.000000)" fill-rule="nonzero">
                  <g id="警示灯" transform="translate(1875.000000, 375.500000)">
                    <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="22" height="22" />
                    <path
                      d="M5,12.5 C5,9.18650001 7.6865,6.50000001 11,6.50000001 C14.3135,6.50000001 17,9.18650001 17,12.5 L17,20 L5,20 L5,12.5 Z"
                      id="路径" fill="#FFFFFF" />
                    <polygon id="路径" fill="#FFD500"
                      transform="translate(11.000000, 14.000000) rotate(-345.000000) translate(-11.000000, -14.000000) "
                      points="11 9 8 14.0049142 10.9820496 15.2536857 9.50006698 19 14 14.0049142 10.9820496 12.7561428" />
                    <path
                      d="M3.99999999,12.5 C3.99999999,8.63400002 7.13399998,5.5 11,5.5 C14.866,5.5 18,8.63399999 18,12.5 L18,20 C18,20.5522848 17.5522847,21 17,21 L4.99999999,21 C4.44771526,21 3.99999999,20.5522848 3.99999999,20 L3.99999999,12.5 Z M11,7.49999999 C8.2385,7.49999999 6.00000001,9.73849998 6.00000001,12.5 L6.00000001,19 L16,19 L16,12.5 C16,9.73850001 13.7615,7.49999999 11,7.49999999 Z"
                      id="形状" fill="#FFFFFF" />
                    <path
                      d="M2.50000001,19 L19.5,19 C20.0522848,19 20.5,19.4477153 20.5,20 C20.5,20.5522847 20.0522848,21 19.5,21 L2.50000001,21 C1.94771526,21 1.50000001,20.5522847 1.50000001,20 C1.50000001,19.4477153 1.94771526,19 2.50000001,19 Z M12,2 L12,4 L12,4 L9.99999999,4 L9.99999999,2 C9.99999999,1.44771525 10.4477152,1 11,1 C11.5522847,1 12,1.44771525 12,2 Z M18.7070833,5.70708332 L17.293,7.1215 L17.293,7.1215 L15.8785,5.707 L17.2929167,4.29291667 C17.6834415,3.9024839 18.3165212,3.90252121 18.707,4.293 C19.0974788,4.6834788 19.0975161,5.31655851 18.7070833,5.70708332 Z M4.70708331,4.29291667 L6.12150001,5.707 L6.12150001,5.707 L4.70700001,7.1215 L3.29291668,5.70708332 C2.90248391,5.31655851 2.90252121,4.6834788 3.293,4.293 C3.68347879,3.90252121 4.3165585,3.9024839 4.70708331,4.29291667 Z"
                      id="形状" fill="#FFFFFF" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <span class="dib f8">警灯</span>
        </div>
        <!-- v-if="!wsShow" -->
        <div class="w48 h48 item mb3 cf tc cp pr" :class="stkshow ? 'selectItem' : ''">
          <div @click="stk('wrj')">
            <img src="@/assets/images/font/stk.png" alt />
            <span class="dib f8">视图库</span>
          </div>
        </div>
        <div class="stk" v-if="stkshow">
          <div class="stk_head">
            <div :class="isfk == 1 ? 'ac' : ''" @click="isflyk(1)">视图</div>
            <div :class="isfk == 2 ? 'bc' : ''" @click="isflyk(2)">机载</div>
          </div>
          <div class="content">
            <!-- 先注释，下次更新要用 -->
            <div class="head" :style="isfk == 2 ? 'justify-content: space-between;' : 'justify-content: flex-end;'">
              <div class="bt jcsb" v-if="isfk == 2">
                <div class="btns" @click="submit">转存</div>
                <div class="btns ml12" @click="handleCheckAllChange">全选</div>
              </div>
              <div class="select" v-if="isfk == 2">
                <div class="btns" @click="changephotoType(0)">图片</div>
                <div class="btns" @click="changephotoType(1)">视频</div>
                <span class="iconfont icon-gengxin" @click="reset"></span>
              </div>
              <div class="select" v-if="isfk == 1">
                <div class="btns" @click="changephotoType(1)">图片</div>
                <div class="btns" @click="changephotoType(2)">视频</div>
                <span class="iconfont icon-gengxin" @click="reset"></span>
              </div>
            </div>
            <div class="lists">
              <!-- <div style="text-align: left">asfnjkfassfausfasn</div> -->
              <div class="boxs" v-if="isfk == 1">
                <div class="task" v-for="(item, index) in photoList" :key="index">
                  <div class="name">{{ item.taskName }}</div>
                  <div class="images">
                    <div class="box" v-for="(item2, index2) in photoList[index].list" :key="index2"
                      :class="isfk == 1 && filetypev2 == 2 ? 'boxv2' : ''">
                      <!-- <el-image v-if="isfk == 1 && filetypev2 == 1" :src="imgApi + '/uploads' + item2.fileKey"
                        fit="cover" :preview-src-list="[imgApi + '/uploads' + item2.fileKey]"></el-image> -->
                      <img @click="lookBig(item2)" :src="imgApi + '/uploads' + item2.fileKey" />
                      <video controls v-if="isfk == 1 && filetypev2 == 2"
                        :src="imgApi + '/uploads' + item2.fileKey"></video>
                      <div class="down" v-if="isfk == 1 && filetypev2 == 1" @click="download(item2, index2)">下载</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="boxs jz" v-else>
                <el-checkbox-group v-model="checkList">
                  <div class="box" v-for="(item, index) in photoList" :key="index"
                    :class="isfk == 2 && filetype == 1 ? 'boxv2' : ''">
                    <el-checkbox :label="item">
                      <el-image v-if="isfk == 2 && filetype == 0" :src="item.jsonFilePath" fit="cover"
                        :preview-src-list="[item.jsonFilePath]"></el-image>

                      <video controls v-if="isfk == 2 && filetype == 1" :src="item.jsonFilePath"></video>
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 警灯控制 -->
      <div v-if="police == true">
        <div class="police" v-interact>
          <div class="police_head">
            <div class="police_name">
              <div class="police_icon"></div>
              <div class="police_font">警灯</div>
            </div>
            <div class="police_font" @click="police = false">关闭</div>
          </div>
          <div class="police_ceonter">
            <!-- 警灯模式 -->
            <div class="police_controls">
              <div class="plice_fontTwo">警灯模式:</div>
              <div>
                <el-radio v-model="taillights" label="1" @change="police_kong(1, 1)">关闭</el-radio>
                <el-radio v-model="taillights" label="2" @change="police_kong(1, 2)">慢闪</el-radio>
                <el-radio v-model="taillights" label="3" @change="police_kong(1, 3)">快闪</el-radio>
                <el-radio v-model="taillights" label="4" @change="police_kong(1, 4)">交替闪</el-radio>
              </div>
            </div>
            <!-- 尾灯 -->
            <div class="police_controls">
              <div class="plice_fontTwo">尾灯:</div>
              <div>
                <el-radio v-model="weideng" label="1" @change="police_wei(2, 1)">开</el-radio>
                <el-radio v-model="weideng" label="2" @change="police_wei(2, 2)">关</el-radio>
              </div>
            </div>
            <!-- 降落伞灯 -->
            <div class="police_controls">
              <div class="plice_fontTwo">降落伞灯:</div>
              <div>
                <el-radio v-model="parachute" label="1" @change="police_jiang(3, 1)">开</el-radio>
                <el-radio v-model="parachute" label="2" @change="police_jiang(3, 2)">关</el-radio>
              </div>
            </div>
            <!-- 隐蔽模式 -->
            <div class="police_controls">
              <div class="plice_fontTwo">隐蔽模式:</div>
              <div>
                <el-radio v-model="take" label="1" @change="police_yin(4, 1)">开</el-radio>
                <el-radio v-model="take" label="2" @change="police_yin(4, 2)">关</el-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ControlList @clearId="$emit('clearId')" @closeIconShow="iconShow = false" :wsShow="wsShow" :device="device"
        :jcData="jcData" :username="username" :controlTypeFlag="controlTypeFlag"
        :class="controlTypeFlag ? 'ycStyle' : 'wrjStyle'" @airway-display="$emit('airway-display')"
        @uav-location="$emit('uav-location')" @yxrz="$emit('yxrz')" @fn="fn" @fun="(data) => fun(data)"
        @exit="controlListFlag = false" v-if="controlListFlag"></ControlList>
      <Mount @fn="fn" @fun="(data) => fun(data)" :taskId="taskId" :device="device" :wsShow="wsShow"
        :uav-mounts="uavMounts" :uav-mounts-payload="uavMountsPayload" :mount="mount" v-if="MountFlag"
        @selectCountItem="selectCountItemFn">
      </Mount>
      <!-- 喊话器 -->
      <MMCGimbalP1 class="PagerP1" v-show="MMCGimbalP1Flag" ref="MMCGimbalP1" @close="MMCGimbalP1Flag = false" />
      <!-- -->
      <MountController @webscoketFn="(data) => fun(data)" v-if="device && device.deviceHardId" :moutedObj="moutedObj"
        ref="MountControllerRef" />
    </div>
    <!-- 结束任务提示框 -->
    <el-dialog title :visible.sync="endRenwu" width="30%" :append-to-body="true" style="margin-top: 20vh">
      <div class="endrenwu">
        <div class="tishiyu">温馨提示</div>
        <div class="queding">您确定要结束任务吗？</div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="endRenwu = false">取消</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="queding">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from "@/api";
import mqtt from "mqtt";
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";
import ControlList from "@/components/observe/fckernel/uav/control/controlList/index";
import Mount from "@/components/observe/fckernel/uav/control/components/mount";
import MountController from "@/components/observe/mountController"; //挂载列表
import MMCGimbalP1 from "@/components/PagerP1"; //喊话器p1
import mixinJoystick from "./mixins/joystick";
import { mapGetters } from "vuex";
import Traffic from "./AI/traffic"; //交通指引
import Face from "./AI/face"; //人脸识别
import Car from "./AI/car"; //车牌识别
import Jm from "./AI/Jm"; //快速建模

import methods from "./methods";

// 引用bus
import Bus from "@/assets/ligature.js";
const { VUE_APP_FCMQTT_URL, VUE_APP_FCMQ_URL } = process.env;
export default {
  name: "ControlMenu1027",
  mixins: [mixinJoystick],
  components: {
    SymbolIcon,
    ControlList,
    Mount,
    MountController,
    MMCGimbalP1,
    Traffic,
    Face,
    Car,
    Jm
  },
  props: {
    wsShow: {
      type: Boolean,
      default: false
    },
    iconShow: {
      type: Boolean,
      default: false
    },
    uavMounts: {
      type: Array,
      default: () => []
    },
    wrjType: {
      type: Object,
      default: () => ({})
    },
    uavMountsPayload: {
      type: Array,
      default: () => []
    },

    mount: {
      type: Array,
      default: () => []
    },
    taskType: {
      type: String,
      default: () => "itemA"
    },
    jcData: {
      type: Object,
      default: () => ({})
    },
    device: {
      type: Object,
      default: () => ({})
    },
    taskstyle: {
      type: Boolean,
      default: false
    }
  },
  inject: ["uav_This"],

  data() {
    return {
      devicews: null,
      jmflag: false,
      imgApi: process.env.VUE_APP_IMG_URL,
      stkshow: false,
      yyrw: false,
      yjfh: false,
      aqjl: false,
      sd: false,
      zd: false,
      typeDj: false, //大疆
      isfk: 1,
      pageNo: 1,
      photoList: [],
      checkList: [],
      filetype: 0,
      filetypev2: 1,
      username: null,
      moutedObj: {},
      MMCGimbalP1Flag: false,
      controlListFlag: false,
      controlTypeFlag: false,
      MountFlag: false,
      unlockType: true,
      MenuList: [{}],
      police: false, //弹框警灯显示隐藏
      taillights: 0, // 警灯模式
      weideng: 0, // 尾灯
      parachute: 0, // 降落伞邓
      take: 0, //隐蔽模式
      client_mqtt: {},
      datas: null,
      msg: "",
      // taskstyle: false, //开关锁
      openAIList: false, //打开AI列表
      openTrafiic: false, //打开交通指引
      openFace: false, //打开人脸识别
      openPlate: false, //车牌识别
      endRenwu: false, //结束任务提示框
      contimer: null, //定时发送数据
      modeList: [
        { id: 1, data: "MANUAL", label: "手动模式", disabled: true },
        { id: 2, data: "ALTITUDE", label: "定高模式", disabled: false },
        { id: 3, data: "POSITION", label: "定点模式", disabled: false },
        { id: 4, data: "AUTO_MISSION", label: "航线模式", disabled: false },
        { id: 5, data: "ACRO", label: "特技模式", disabled: true },
        { id: 6, data: "OFF_BOARD", label: "版外模式", disabled: true },
        { id: 7, data: "STABILIZED", label: "自稳模式", disabled: true },
        { id: 8, data: "HOLD", label: "保持模式", disabled: false },
        { id: 9, data: "LAND", label: "降落模式", disabled: true },
        { id: 10, data: "TAKE_OFF", label: "起飞模式", disabled: true },
        { id: 11, data: "AUTO_RTL", label: "返航模式", disabled: false },
        {
          id: 12,
          data: "AUTO_FOLLOW_TARGET",
          label: "自动跟随模式",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
    uavId() {
      return this.device?.deviceHardId;
    },
    taskId() {
      return this.$store.state.fckernel.YJtaskId;
    }
  },
  watch: {
    "device.deviceHardId"(val) {
      this.$emit('changeTask', false)
      this.stkshow = false
      this.police = false
      this.controlListFlag = false
      this.MountFlag = false
      this.$emit('changeTask', true)
      if (val) {
        this.typeDj = true
        this.unlockType = true;
      }
      if (!this.wsShow) {
        if (val) {
          this.unlockType = true;
          //自动模式
          this.closeJoystick();
          if (this.client) {
            this.client.end();
            this.client = null;
          }
          // 摇杆 mqtt初始化/
          this.initMqtt();
        }
      }
    },
    // "$store.state.shoudong.Mstate"(vla, old) {
    //   if (vla == "定点模式") {
    //     this.initJoystick(this.device);
    //     if (this.uavModel == true) {
    //       if (this.$store.state.shoudong.flag == 0) {
    //         this.unlockType = true;
    //       } else if (this.$store.state.shoudong.flag == 1) {
    //         this.unlockType = false;
    //       } else {
    //         this.unlockType = true;
    //       }
    //     }
    //   }
    // },
    deep: true
  },
  created() {
    // 修改飞控 无人机 左边'任务库'的位置
    // Bus.$on("ydh", status => {
    //   // console.log(status,'11212');
    //   if (status == false) {
    //     this.taskstyle = false;
    //   } else {
    //     this.taskstyle = true;
    //   }
    // });


    // Bus.$on('wuhu_fly_task_end', () => {
    //   console.log('end 结束任务');
    //   this.end();
    // });

  },
  async mounted() {
    this.username = this.user_info.username;
    let res = await API.FCKERNEL.getTaskAndUserRelation({
      sourceType: 0,
      deviceHardId: this.device.deviceHardId
    });
    if (res.code == 200 && res.list) {
      this.uav_This.iconShow = true;
    } else if (res.code == 201) {
      this.$message.warning(res.msg)
    }
    // 摇杆 mqtt初始化
    if (!this.wsShow) {
      this.initMqtt();
    }
    this.$emit('uav-location')
    // 初始化 设备监听ws
    this.initdevicews()

    // 结束任务
    // Bus.$on('wuhu_fly_task_end', () => {
    //   console.log('end 结束任务');
    //   this.end();
    // });
    //
    //连接
  },
  beforeDestroy() {
    if (this.client) {
      this.client.end();
      this.client = null;
    }
    this.devicews && this.devicews.close()
    this.devicews = null;
  },
  methods: {
    ...methods,
    initdevicews() {
      let ws_url_al = process.env.VUE_APP_WS_URL_ALARM;
      this.devicews = new WebSocket(ws_url_al);
      let { appid, username } = JSON.parse(
        localStorage.getItem("user_info")
      ).data;
      let token = JSON.parse(localStorage.getItem("user_info")).data[
        "mmc-identity"
      ];
      this.devicews.onopen = () => {
        this.devicews.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId: appid,
          })
        );
      };
      this.devicews.onmessage = async (e) => {
        let metadata = null;
        try {
          metadata = JSON.parse(e.data);
        } catch (e) {
          return;
        }
        if (metadata.msgnum == 4143) {
          // 失去接管权限 
          // if (metadata.isAgree) {
          this.unlockType = true;
          this.closeJoystick();
          this.MountFlag = false;
          // }
          this.$message.success(metadata.data.msg);
        }
      };
    },
    async end() {
      this.endRenwu = true;
    },
    //大疆摇杆控制
    changDj(val) {

      if (val == 1) {
        let a = document.createElement("a");
        a.href = "JoystickTools://"
        a.click()
        //启用摇杆
        this.$emit("fun", {
          type: 200,
          systemCode: "mmc",
          state: 1,
          username: this.username,
          data: {
            cmdFunction: 2121
          },
          deviceHardId: this.device.deviceHardId
        });
        this.typeDj = true;
        setTimeout(() => {
          this.initJoystick(this.device)
        }, 5000);
        this.contimer = setInterval(() => {
          this.send_ws()
        }, 100);
      } else {
        //释放摇杆
        this.$emit("fun", {
          type: 200,
          systemCode: "mmc",
          state: 1,
          username: this.username,
          data: {
            cmdFunction: 2122
          },
          deviceHardId: this.device.deviceHardId
        });
        this.typeDj = false;
        this.closeJoystick();
        clearInterval(this.contimer)
      }
    },
    // 警灯控制
    // xing:类型：1警灯，2尾灯，3降落伞灯，4隐蔽
    // num：警灯模式下：1关闭，2慢闪，3快闪，4交替闪，其他模式为1打开，2关闭
    police_kong(xing, num) {
      this.datas = {
        data: {
          param1: xing,
          param2: num
        },
        type: 531
      };
      // console.log(this.datas, "警灯清空");
      let device = this.device;
      let uavCate = device.cateName == "px4" ? "PX4" : "APM";
      let topic = `${uavCate}/OBTAIN/${device.deviceHardId}`;
      console.log(device);
      // this.client.publish(topic, this.datas);
      this.publish(topic, this.datas, 212);
      // this.poice_mqtt();
    },
    // 尾灯胖丁
    police_wei(xing, num) {
      this.datas = {
        data: {
          param1: xing,
          param3: num
        },
        type: 531
      };
      let device = this.device;
      let uavCate = device.cateName == "px4" ? "PX4" : "APM";
      let topic = `${uavCate}/OBTAIN/${device.deviceHardId}`;
      console.log(device);
      // this.client.publish(topic, this.datas);
      this.publish(topic, this.datas, 212);
      // this.poice_mqtt();
    },
    // 降落山邓
    police_jiang(xing, num) {
      this.datas = {
        data: {
          param1: xing,
          param3: num
        },
        type: 531
      };
      let device = this.device;
      let uavCate = device.cateName == "px4" ? "PX4" : "APM";
      let topic = `${uavCate}/OBTAIN/${device.deviceHardId}`;
      console.log(device);
      // this.client.publish(topic, this.datas);
      this.publish(topic, this.datas, 212);
      // this.poice_mqtt();
    },
    // 隐蔽模式
    police_yin(xing, num) {
      this.datas = {
        data: {
          param1: xing,
          param4: num
        },
        type: 531
      };
      let device = this.device;
      let uavCate = device.cateName == "px4" ? "PX4" : "APM";
      let topic = `${uavCate}/OBTAIN/${device.deviceHardId}`;
      console.log(device);
      // this.client.publish(topic, this.datas);
      this.publish(topic, this.datas, 212);
      // this.poice_mqtt();
    },
    // 警灯框判定
    async application() {
      // 查看是否有权限接管无人机
      let res = await this.checkUseOperateFn(this.device.deviceHardId);
      // 判断当前状态 true为一接管
      if (res == false) {
        return;
      }
      this.police = true;
    },
    fn(data, type) {
      // console.log('fn', data, type)
      this.$emit("fn", data, type);
    },
    selectCountItemFn(data) {
      if (data.mountName == "MMC_Gimbal_P1_4G") {
        if (this.$refs.MMCGimbalP1) {
          this.$refs.MMCGimbalP1.PoliceKeyCode = data.deviceHardId;
          this.$refs.MMCGimbalP1.init();
          this.MMCGimbalP1Flag = true;
        }
      } else {
        let MountControllerDom = this.$refs.MountControllerRef;
        console.log(MountControllerDom);
        if (MountControllerDom) {
          MountControllerDom.mountName = "";
          MountControllerDom.deviceHardId = "";
          if (data) {
            MountControllerDom.deviceHardId = data.deviceHardId;
            MountControllerDom.mountName = data.mountName;
          }
        }
      }
    },
    fun(data) {
      this.$emit("fun", data);
    },
    initMqtt() {
      if (this.client) {
        this.client.end();
        this.client = null;
      }
      let url = VUE_APP_FCMQTT_URL;
      const crypto =
        window.crypto ||
        window.webkitCrypto ||
        window.mozCrypto ||
        window.oCrypto ||
        window.msCrypto;
      let uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
      this.client = mqtt.connect(url, {
        protocol: "mqtt",
        clientId: uuid
      });
      // console.log(this.client, "qqqqqqqqqq");
      this.client.on("connect", () => {
        console.log(
          "src/components/observe/fckernel/uav/control/controlMenu mqtt成功"
        );
      });
      this.client.on("close", function (params) {
        console.log(
          "src/components/observe/fckernel/uav/control/controlMenu mqtt断开"
        );
      });
      this.client.on("error", err => {
        console.log(
          "src/components/observe/fckernel/uav/control/controlMenu 当客户端无法链接（rc！=0） 或出现解析时发出",
          err
        );
      });
      this.client.on("reconnect", err => {
        console.log(
          "src/components/observe/fckernel/uav/control/controlMenu 正在重连",
          err
        );
      });
      this.client.on("offline", err => {
        console.log(
          "src/components/observe/fckernel/uav/control/controlMenu mqtt客户端脱机",
          err
        );
      });
      this.client.on("message", (topic, payload, packet) => {
        let data = JSON.parse(payload.toString());
        if (data.type == 270) {
          if (data.data.cmd == "10062") {
            // this.init()
          }
        }
      });
    },

    subscribe(topic, callback) {
      if (!topic) {
        return topic;
      }
      let { client } = this;
      if (client) {
        client.subscribe(topic, callback);
      }
    },
    publish(topic, message, callback, options = { qos: 0 }) {
      let { client } = this;
      if (client) {
        client.publish(
          topic,
          new TextEncoder().encode(JSON.stringify(message)),
          options,
          callback
        );
        console.log("触发了mqtt");
        console.log(message);
      }
    },
    async changeType() {
      // 飞控 无人机 手动=>定点模式
      // 关键字：无人机模式修正
      // Bus.$emit("uavState", "AUTO_MISSION");
      this.$emit("fn", { type: 513, data: "AUTO_MISSION" });
      // 权限判断
      let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      if (!flag) return;
      this.unlockType = true;
      this.closeJoystick();
      // 飞控 无人机 自动=>航线模式
    },
    taskUpdate(status, flyTypeId, deviceHardId) {
      API.DEVICE.task_update({
        id: flyTypeId,
        status,
        deviceHardId
      });
    },
    mqtt_fn(data, fn) {
      let device = this.device;
      if (device) {
        let uavCate = device.cateName == "px4" ? "PX4" : "APM";
        let topic = `${uavCate}/OBTAIN/${device.deviceHardId}`;
        this.publish(topic, data, fn);
      }
    },
    async unlock() {
      // console.log('src/components/observe/fckernel/uav/control/controlMenu message 接管无人机');
      // 飞控 无人机 自动=>航线模式
      // 飞控 无人机 手动=>定点模式
      // 关键字：无人机模式修正
      // Bus.$emit("uavState", "POSITION");
      this.$emit("fn", { type: 513, data: "POSITION" });
      let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      if (!flag) return;
      // 打开软件
      let a = document.createElement("a");
      a.href = "JoystickTools://"
      a.click()
      setTimeout(() => {
        this.initJoystick(this.device)
      }, 5000);
      // this.initJoystick(this.device);
    },
    async controlListFlagFn(item) {
      // let flag = await this.checkUseOperateFn(this.device.deviceHardId)
      // if (!flag) return;
      this.controlListFlag = false;
      if (item == "yc") {
        this.controlTypeFlag = true;
      } else {
        this.controlTypeFlag = false;
      }
      this.MountFlag = false;
      this.police = false;
      this.controlListFlag = true;
    },
    async policeShow() {
      let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      if (!flag) return;
      this.police = true;
      this.stkshow = false
      this.MountFlag = false;
      this.controlListFlag = false;
    },
    async mountFn() {
      let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      if (!flag) return;
      this.MountFlag = !this.MountFlag;
      if (this.MountFlag) {
        this.controlListFlag = false;
        this.police = false;
      }
    },
    async checkUseOperateFn(device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device });
      console.log(res);
      if (res.code == 201) {
        this.$message.warning(res.msg);
        return false;
      } else {
        return true;
      }
    },
    // 刷新时查看时手动还是自动
    async shou_zi(device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device });
      console.log(res);
      if (res.code == 201) {
        return false;
      } else {
        return true;
      }
    },
    async start() {
      let waypointList = JSON.parse(sessionStorage.getItem("waypointList"));
      if (waypointList) {
        let checkBeforeTakeOff = await API.DEVICE.checkBeforeTakeOff({
          taskId: waypointList.flyTypeId
        });
        if (!checkBeforeTakeOff.code) {
          console.log(checkBeforeTakeOff, "checkBeforeTakeOff");
          this.$emit("Lsdom", checkBeforeTakeOff);
          return;
        }
        this.$confirm("请确认是否进行一键任务操作", "安全确认", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          customClass: "uav_controlPane",
          showClose: false
        })
          .then(async () => {
            if (!this.wsShow) {
              // // 生成架次號
              let getFlightSortic = await API.DEVICE.getFlightSortic({
                taskId: waypointList.flyTypeId,
                deviceHardId: waypointList.uavDeviceId
              });
              // 上传航线指令
              this.$emit("fn", {
                type: 521,
                data: {
                  taskId: waypointList.flyTypeId,
                  flightSortiesID: getFlightSortic,
                  ...waypointList.wayLineObj
                }
              });
              // 告诉飞控开始任务,并且把架次号和 任务id传过去
              this.startFlight({
                taskId: waypointList.flyTypeId,
                flightSortiesID: getFlightSortic
              });
              // 更改任务状态 status 任务执行状态 默认-1待派发 0表示待执行；3执行中;1执行完成
              this.taskUpdate(
                3,
                waypointList.flyTypeId,
                waypointList.uavDeviceId
              );
              // this.$emit('airway-display')
              this.iconShow = true;
              setTimeout(() => {
                this.$emit("fn", {
                  data: 0,
                  type: 522
                });
              }, 3000);
            } else {
              //地面站链路
              //上传航线
              // console.log(waypointList, "waypointList33333333333");
              this.$emit("fun", {
                type: 200,
                systemCode: "mmc",
                state: 1,
                username: this.username,
                data: {
                  cmdFunction: 2113,
                  // cmdValue: waypointList.flightId, //航线id
                  cmdValue: waypointList.flightLineId, //航线id
                  taskId: waypointList.flyTypeId + ""
                },
                deviceHardId: this.device.deviceHardId
              });
              //解锁无人机
              // this.$emit("fun", {
              //   type: 200,
              //   systemCode: "mmc",
              //   state: 1,
              //   username: this.username,
              //   data: {
              //     cmdFunction: 2110
              //   },
              //   deviceHardId: this.device.deviceHardId
              // });
              //预览航线
              // this.$emit("fun", {
              //   type: 200,
              //   systemCode: "mmc",
              //   state: 1,
              //   username: this.username,
              //   data: {
              //     cmdControlType: 900,
              //     cmdFunction: 9001
              //   },
              //   deviceHardId: this.device.deviceHardId
              // });
              //一键起飞
              // this.$emit("fun", {
              //   type: 200,
              //   systemCode: "mmc",
              //   state: 1,
              //   username: this.username,
              //   data: {
              //     cmdFunction: 2111,
              //     cmdValue: 50, //高度
              //     taskId: waypointList.flyTypeId
              //   },
              //   deviceHardId: this.device.deviceHardId
              // });
              // status 任务执行状态 默认-1待派发 0表示待执行；3执行中;1执行完成
              let task = await API.DEVICE.task_update({
                id: waypointList.flyTypeId,
                status: 3,
                deviceHardId: this.device.deviceHardId
              });
              // 一键起飞任务监听
              let res = await API.TASK.flytaskLisener({
                taskId: waypointList.flyTypeId,
                deviceHardId: this.device.deviceHardId
              });
              //航线模式
              // this.$emit("fun", {
              //   type: 200,
              //   systemCode: "mmc",
              //   state: 1,
              //   username: this.username,
              //   data: {
              //     cmdFunction: 2115
              //   },
              //   deviceHardId: this.device.deviceHardId
              // });
            }
          })
          .catch(error => {
            console.log(error, "error");
            this.$message({
              type: "info",
              message: "已取消操作"
            });
          });
      } else {
        this.$message.warning("请选择任务");
      }
    },
    startFlight(data) {
      // 开始任务
      this.$emit("fn", {
        data,
        type: 769
      });
    },
    async handClick(item) {
      // 权限判断
      let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      if (!flag) return;
      if (item == 0) {
        await this.$confirm("请确认是否进行一键任务操作", "安全确认", {
          center: true
        });
        this.start();
      } else if (item == 1) {
        //一键返航
        await this.$confirm("请确认是否进行一键返航操作？", "安全确认", {
          center: true
        });
        console.log('返航', this.wsShow)
        if (!this.wsShow) {
          // Bus.$emit("uavFan", "AUTO_RTL");
          this.handle_change_uav_mode('AUTO_RTL');
        } else {
          this.$emit("fun", {
            type: 200,
            systemCode: "mmc",
            state: 1,
            username: this.username,
            data: {
              cmdFunction: 2112
            },
            deviceHardId: this.device.deviceHardId
          });
        }
      }
    },
    async queding() {
      let data = JSON.parse(sessionStorage.getItem("waypointList"));
      if (data) {
        if (!this.wsShow) {
          let flag = await this.checkUseOperateFn(data.uavDeviceId);
          if (!flag) return false;
          // 结束任务
          API.DEVICE.task_update({
            id: data.flyTypeId,
            status: 4,
            deviceHardId: data.uavDeviceId
          });
          // 绑定任务
          API.FCKERNEL.updateTaskAndUserRelation({
            taskId: data.flyTypeId
          });
          // this.$emit("fn", { type: 525 }, "wrj");
          // 飞控结束任务
          this.$emit(
            "fn",
            {
              type: 769
            },
            "wrj"
          );
        } else {
          // 结束任务
          API.DEVICE.task_update({
            id: data.flyTypeId,
            status: 4,
            deviceHardId: data.uavDeviceId
          });
          // 绑定任务
          API.FCKERNEL.updateTaskAndUserRelation({
            taskId: data.flyTypeId
          });
          this.$emit("fun", {
            type: 200,
            systemCode: "mmc",
            state: 1,
            username: this.username,
            data: {
              cmdFunction: 2280,
              taskId: data.flyTypeId + ""
            },
            deviceHardId: data.uavDeviceId
          });
        }
        this.$emit("changeTask", false);
        this.$emit("changeTask", true);
        sessionStorage.removeItem("waypointList");
        this.$message.success("操作成功");
        // 删除飞控 无人机 模型和航线
        // 搜索关键字：删除飞控模型
        Bus.$emit("remove_model");
        this.$emit("clearId");
        this.$emit("closeIconShow");
      } else {
        this.$message.warning("暂无飞行任务");
      }
      this.endRenwu = false;
    },
    /**
     * 切换AI功能
     */
    switchAI(type) {
      this.$emit("changeTask", false);
      this.openFace = false;
      this.openPlate = false;
      this.openTrafiic = false;
      this.jmflag = false
      switch (type) {
        case 0:
          this.openFace = !this.openFace;
          break;

        case 1:
          this.openPlate = !this.openPlate;
          break;

        case 2:
          if (this.taskId == null) {
            return this.$message.error("暂无绑定任务！")
          }
          this.openTrafiic = !this.openTrafiic;
          break;
        case 3:
          this.jmflag = !this.jmflag

          break;

      }
    },

    closeAI() {
      this.openAIList = false;
      this.openFace = false;
      this.openPlate = false;
      this.openTrafiic = false;
      this.$emit("changeTask");
    },
    async handle_change_uav_mode(data) {
      // 查看是否有权限接管无人机
      let res = await this.checkUseOperateFn(this.device.deviceHardId);
      // 判断当前状态 true为一接管
      if (res == false) {
        return;
      }
      let mode = this.modeList.find((item) => item.data === data);
      if (mode) {
        this.$emit("fn", { type: 513, data: mode.data });
        this.$message.success("操作成功");
      }
      console.log('返航成功------------------')
    },
  }
};
</script>
<style lang="scss" scoped>
.controlMenuBox {
  // position: fixed !important;
  // right: 20px;
  // top: 130px;
  // width: 48px;

  .item {
    padding: 5px;
    background: rgba(9, 32, 87, 0.7);
    border: 1px solid transparent;

    &:hover {
      // opacity: 0.8;
      transform: scale(1.2);
    }
  }

  .ycStyle {
    position: absolute;
    top: 0;
  }

  .wrjStyle {
    position: absolute;
    bottom: 0;
  }

  .selectItem {
    border: 1px solid #70daf9;
  }
}

.sdActive {
  background: rgba(0, 128, 10, 0.6) !important;
}

.sdDefault {
  background: rgba(212, 11, 21, 0.747) !important;
}

// 弹框样式
.endrenwu {
  width: 100%;
  height: 177px;
  background: rgba(9, 32, 87, 0.7) !important;
  border: 1px solid #70daf9 !important;
}

.queding {
  color: #92d9ff;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 30px 0 0 0;
}

.tishiyu {
  color: #92d9ff;
  font-size: 18px;
  margin: 30px 0 0 0;
  text-align: center;
  width: 100%;
}

.btn_kuang {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 79px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  margin: 20px 0 0 0;
  cursor: pointer;
}

.btn_lan {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #606266;
}

.btn_lv {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #fff;
}

/deep/ .el-dialog__header {
  margin-left: 0;
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
  z-index: 2023 !important;
}

/deep/ .el-dialog {
  z-index: 2022 !important;
  background: transparent !important;

}

// 警灯弹框
.police {
  width: 475px;
  height: 200px;
  background: rgba(0, 39, 121, 0.5);
  border: 1px solid #43deff;
  backdrop-filter: blur(2px);
  pointer-events: auto;
  position: absolute;
  left: -504px;
  bottom: -179px;

  .police_head {
    background: linear-gradient(180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.45) 40%,
        #05091a 100%);
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #70daf9;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 10px;
    align-items: center;
    box-sizing: border-box;

    .police_name {
      display: flex;

      .police_icon {
        width: 5px;
        height: 15px;
        background: #ffbd36;
        margin: 0 10px 0 0;
      }
    }
  }

  .police_ceonter {
    width: 100%;
    height: calc(100% - 30px);
    padding: 24px;
    box-sizing: border-box;

    .police_controls {
      display: flex;
      margin: 0 0 15px 0;
    }
  }
}

.police_font {
  font-weight: 700;
  color: #2ddcfc;
}

.plice_fontTwo {
  width: 90px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

/deep/ .el-radio__label {
  color: #fff;
}

.bottom_center {
  position: fixed;
  left: calc(51% - 140px);
  bottom: 15%;
  display: flex;

  .one {
    margin: 0 34px 0 0;
  }
}

.right_top {
  position: fixed;
  right: 4%;
  top: 29%;
}

.left-bar {
  position: fixed;

  left: 470px;
  top: 13%;
  transition: 0.3s;

  &.collapse {
    left: 10px;
  }

  .left-bar-item {
    cursor: pointer;
    position: relative;
    width: 48px;
    height: 48px;
    background: rgba(9, 32, 87, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2px;

    .left-bar-item__icon {
      width: 20px;
      height: 20px;
      margin-bottom: 2px;
    }

    .left-bar-item__text {
      font-size: 16px;
      transform: scale(0.6);
      white-space: nowrap;
      color: #fff;
    }
  }

  .ai-list {
    width: 0;
    position: absolute;
    left: 65px;
    top: 62px;
    display: none;

    &.active {
      display: flex;
    }

    .left-bar-item {
      margin-right: 4px;
    }
  }
}

.ai-dialog {
  position: absolute;
  top: 56px;
  left: 0px;
}

.jm-dialog {
  position: absolute;
  top: 56px;
  left: 0px;
  // top: 75px;
  // left: 193px;

}

.stk {
  position: absolute;
  right: 70px;
  top: 190px;
  width: 470px;
  height: 378px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);

  .stk_head {
    display: flex;
    cursor: pointer;

    div {
      padding: 0 8px;
      // width: 243px;
      width: 100%;
      text-align: center;
      line-height: 32px;
      font-size: 16px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 21px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    }

    .ac {
      background: linear-gradient(180deg,
          #9198ff 0%,
          rgba(45, 81, 153, 0.45) 40%,
          #05091a 100%);
      box-shadow: inset 0px 0px 10px 2px #3f9dff;
      border-radius: 10px 0px 0px 0px;
      border: 1px solid #427dff;
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .bc {
      background: linear-gradient(180deg,
          #9198ff 0%,
          rgba(45, 81, 153, 0.45) 40%,
          #05091a 100%);
      box-shadow: inset 0px 0px 10px 2px #3f9dff;
      border-radius: 0px 10px 0px 0px;
      border: 1px solid #427dff;
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .content {
    margin-top: 10px;
    padding: 0 16px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btns {
        width: 64px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        background: url("~@/assets/images/font/zc.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;

        &:hover {
          background: url("~@/assets/images/font/qx.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      .blue {
        background: url("~@/assets/images/font/qx.png") no-repeat;
        background-size: 100% 100%;
      }

      .select {
        display: flex;
        align-items: center;

        .iconfont {
          color: #fff;
        }

        .btns {
          margin-right: 16px;
          width: 64px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          background: url("~@/assets/images/font/zc.png") no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("~@/assets/images/font/qx.png") no-repeat;
            background-size: 100% 100%;
          }
        }

        .el-select {
          width: 80px;
          height: 32px;

          /deep/.el-input--suffix {
            width: 100%;
            height: 100%;

            .el-input__inner {
              width: 100%;
              height: 100%;
            }
          }

          /deep/.el-input__suffix {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .lists {
      // margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .boxs {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      height: 280px;
      overflow: auto;
      margin-top: 14px;

      .task {
        display: flex;
        flex-direction: column;

        .name {
          color: #fff;
        }

        .images {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          // height: 280px;
          overflow: hidden;
          overflow-y: auto;
          margin-top: 14px;

          .box {
            position: relative;
            width: 135px;
            height: 88px;
            background: #ffffff;
            border-radius: 2px;
            margin-left: 8px;
            margin-bottom: 14px;

            .el-image {
              width: 138px;
              height: 88px;
              background: #ffffff;
              border-radius: 2px;
            }

            img {
              width: 135px;
              height: 88px;
            }

            .down {
              position: absolute;
              bottom: 0;
              right: -3px;
              width: 30px;
              height: 20px;
              text-align: center;
              line-height: 20px;
              background: rgba($color: #000000, $alpha: 0.6);
              color: #fff;
              font-size: 12px;
              display: none;
            }

            &:hover {
              .down {
                display: block;
              }
            }
          }

          .boxv2 {
            width: 430px;
            height: 120px;
            margin-left: 0;

            video {
              width: 430px;
              height: 120px;
            }
          }
        }
      }
    }
  }
}

.el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 280px;

  .box {
    width: 135px;
    height: 88px;
    background: #ffffff;
    border-radius: 2px;
    margin-left: 8px;
    margin-bottom: 14px;
  }

  .boxv2 {
    width: 430px;
    height: 120px;
    margin-left: 0;

    video {
      width: 430px;
      height: 120px;
    }
  }
}

// .el-checkbox{
//   width: 135px;
//   height: 88px;
//   margin-right: 0;
//   // margin-left: 6px;
//   // margin-top: 6px;
// }
/deep/.el-checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
}

/deep/.el-checkbox__label {
  padding: 0;
}

.icon-gengxin {
  cursor: pointer;
}
</style>
