<template>
  <div class="history-list-container">
    <div class="tb-box">
      <!-- 鹰击历史任务 -->
      <!-- v-for="(item, index) in list" :key="index" :data="item" -->
      <Card class="card" v-for="(item, index) in list" :data="item" :key="index" @download="downloadHandle"
        @imageShow="imageShowHandle"></Card>
      <!-- <div class="tb-hd-box">
        <div class="  tb-hd dib cf tc fw700 lh36">任务名称</div>
        <div class="tb-hd">任务类型</div>
        <div class="tb-hd">结束时间</div>
        <div class="tb-hd">操作</div>
      </div>
      <div class="tb-bd-box">
        <div class="tb-tr device-fns" v-for="item in list" :key="item.id">
          <el-tooltip :content="item.task_title" placement="top">
            <div class="td cf lh36 td-checkbox">
              {{ item.task_title || "暂无" }}
            </div>
          </el-tooltip>
          <div class="td lh36">
            <div v-if="item.taskCateId == '1'" style="color: #fb4a2d">
              警情任务
            </div>
            <div v-else-if="item.taskCateId == '2'" style="color: #00d45c">
              常态任务
            </div>
            <div v-else style="color: #ffbd36">临时紧急任务</div>
          </div>
          <el-tooltip :content="item.task_title" placement="top">
            <div class="td cf lh36 td-checkbox">
              {{ item.endTime || "暂无" }}
            </div>
          </el-tooltip>
          <div class="jcc operation tc lh36 " style="    margin: 0 auto;">
            <el-tooltip effect="dark" content="图片" placement="top">
              <div @click="handleImage(item)" class="iconfont icon-lishizhaopian"></div>
            </el-tooltip>
            <div :class="{ deng: item.task_status == 1 }" class="iconfont icon-a-xiazai2px lh36 cp budeng ml10 "
              title="导出报告" @click="shengchengbaogaoFn(item)"></div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <ImageMedia v-if="visible" :historyImage="flightTask_imageList"></ImageMedia> -->
  </div>
</template>

<script>
import API from "@/api";
import ImageMedia from "@/components/command/flight-task/image-media/index.vue";
import Card from './card.vue'
import { mapGetters } from "vuex";
export default {
  data () {
    return {
      list: [],
      visible: false
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  components: { ImageMedia, Card },
  created () {
    this.getList();
  },
  provide () {
    return {
      flight_task_chage_view: this.chage_view,
    };
  },
  methods: {
    chage_view () {
      this.visible = false
    },
    // 查看历史图片
    async handleImage (item) {
      try {
        let res = await API.TASK.photoAndvideo({
          pageNo: 1,
          pageSize: 10000,
          taskId: item.id
        })
        console.log(res.data, 'res.datares.data');
        if (res.data && res.data.length) {
          this.flightTask_imageList = res.data
          this.visible = true
        } else {
          this.$el_message('无历史图片', () => { }, 'info')
        }
      } catch (error) {
        this.$el_message('无历史图片', () => { }, 'info')
      }
    },
    async getList () {
      this.list = [];
      let res = await API.ACCIDENT.List();
      this.list = res;
      console.log(this.list, " task_titletask_titletask_titletask_title");
    },
    // 导出报告
    async shengchengbaogaoFn (item, id) {
      console.log(item, id);
      let newId = null;
      if (item) {
        newId = item.id;
      } else {
        newId = id;
      }
      let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/task/exportTaskWord?id=${newId}&FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`;
      console.log(url, 'url');
      const fileName = url;
      if ("download" in document.createElement("a")) {
        let downloadElement = document.createElement("a");
        downloadElement.href = url;
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
      }
    },
    downloadHandle (item, id) {
      this.shengchengbaogaoFn(item, id);
    },
    imageShowHandle (item) {
      this.handleImage(item);
    },
  },
};
</script>

<style scoped lang='scss'>
.history-list-container {
  width: 100%;
  height: 100%;

  .tb-box {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    overflow-y: auto;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;

    .card {
      padding: 5px 0;
      height: 140px !important;
      box-sizing: border-box;
      margin: 10px 0;
    }
  }
}
</style>