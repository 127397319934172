import API from "@/api";
export default {
  lookBig(item){
    this.$emit("imgUrl",this.imgApi + '/uploads' + item.fileKey)
  },
  download(item,i){
    fetch(this.imgApi + '/uploads' + item.fileKey)
          .then(res => res.blob())
          .then(blob => {
            const a = document.createElement("a");
            const objectUrl = window.URL.createObjectURL(blob);
            a.download = item.fileName;
            a.href = objectUrl;
            a.click();
            window.URL.revokeObjectURL(objectUrl);
            a.remove();
          })
},
  stk() {
    this.stkshow = !this.stkshow;
    this.isfk = 1;
    this.police=false
    if (this.stkshow && this.isfk == 1) {
      this.getst();
    }
  },
  //视图
  async getst() {
    this.photoList = []
    let data = {
      deviceHardId: this.uavId,
      // taskId: this.taskId ? Number(this.taskId) : null,
      pageNo: this.pageNo,
      type: this.filetypev2,
      pageSize: 10000,
    };
    let res = await API.FCKERNEL.photoAndvideo(data);
    
    res.data.forEach(val => {
      if(val.list.length > 0){
        // console.log(val,"val");
        this.photoList.push(val)
        this.photoList.forEach(val2=>{
          if(val2.type == this.filetypev2){
            let list = []
            list.push(val2)
            val2.list.push(list)
          }
        })
      }
    })
  },
  //机载
  async getjz() {
    this.photoList = []
    let data = {
      deviceId: this.uavId,
      taskId: this.taskId ? Number(this.taskId) : null,
      pageNo: this.pageNo,
      pageSize: 10000,
      type: this.filetype,
    };
    let res = await API.FCKERNEL.devicephoto(data);
    this.photoList = res.list;
    let blob = null
    this.photoList.forEach(async(val)=>{
      let res = await API.FCKERNEL.fileUrl('http://32.128.5.10:20251'+ val.filePath)
      if(this.filetype == 0){
         blob = new Blob([res],{type: "image/jpeg"})
      }else{
         blob = new Blob([res],{type: "video/mp4"})
      }
      val.jsonFilePath = URL.createObjectURL(blob)
    })
  },
  async reset() {
    if (this.isfk == 1) {
      this.getst()
    } else {

    }
  },

  isflyk(val) {
    this.isfk = val;
    if (this.isfk == 1) {
      this.getst();
    } else {
      this.getjz();
    }
  },
  changephotoType(val) {
    if (this.isfk == 1) {
      this.filetypev2 = val;
      this.getst()
    } else {
      this.filetype = val;
      this.getjz()
    }
  },
  handleCheckAllChange() {
    this.checkList = this.photoList
  },
  submit() {
    console.log(this.checkList);
    if(this.checkList.length > 0){
      this.checkList.forEach(async (val)=>{
      let blob = await API.FCKERNEL.fileUrl('http://32.128.5.10:20251'+ val.filePath)
      let fd = new FormData();
        fd.append("uploadFile", blob, `拍照.png`);
        if(this.taskId){
          fd.append("taskId", this.taskId);
        }
        fd.append("deviceHardId", this.uavId);
        let res = await API.FCKERNEL.Upload(fd);
      })
    }
  },
};