<template>
  <div class="cpt-command-airway-edit">
    <div class="header" v-interact>
      <div class="hd-box">手动规划</div>
      <div style="color: #08c2d1" class="hd-box cp">
        <!-- <div @click="change_airway" v-if="isShow">
          <span
            class="iconfont"
            :class="flag ? 'icon-shebeiliebiao' : 'icon-shebeiliebiao'"
          ></span>
          <span class="ml5 mr5">{{ flag ? "机巢" : "无人机" }}</span>
          <span
            class="iconfont icon-shujushangchuan"
            :class="flag ? 'icon-shujushangchuan' : 'icon-shujushangchuan'"
          ></span>
        </div> -->
      </div>
      <div class="hd-box cp mr20" @click="exit">关闭</div>
    </div>
    <manual
      :nestData="data"
      v-if="visibleFlag"
      :flag="flag"
      @add="testEmit"
    ></manual>
    <autoPlan v-if="!visibleFlag" :flag="flag" @add="testEmit"></autoPlan>
  </div>
</template>

<script>
import autoPlan from "./autoPlan";
import manual from "./manual";
import dayjs from "dayjs";
let entitie = null;
export default {
  components: {
    autoPlan,
    manual,
  },
  props: {
    //为true是 规划航线为机巢
    flag: {
      type: Boolean,
      default: false,
    },
    // 是否展示切换按钮
    data: {
      type: Object || Array,
      default: () => {},
    },
  },
  data() {
    return {
      // 手动规划还是自动规划
      visibleFlag: true,
    };
  },
  watch: {
    data: {
      handler(value) {
        this.showAreaFn(value);
      },
      immediate: true,
      deep: true,
    },
  },
  beforeDestroy() {
    window.viewer.entities.remove(entitie);
  },
  methods: {
    showAreaFn(data) {
      if (entitie) {
        window.viewer.entities.remove(entitie);
      }
      let positions = Cesium.Cartesian3.fromDegrees(
        Number(data.longitude),
        Number(data.latitude),
        0
      );
      let ellipse = {
        semiMinorAxis: 2500,
        material: Cesium.Color.fromCssColorString("#2f6947").withAlpha(0.7),
        semiMajorAxis: 2500,
        rotation: Cesium.Math.toRadians(-40.0),
        outline: true,
        outlineWidth: 4,
        stRotation: Cesium.Math.toRadians(90),
      };
      let entities = this.createAreaPoint(window.viewer, {
        id: "showArea" + data.id + "yc",
        position: positions,
        title: data.name,
        ellipse,
      });
      entitie = entities;
    },
    createAreaPoint(viewer, { position, title, id, ellipse, longitude }) {
      let point = viewer.entities.add({
        id: id || null,
        name: "pointArea",
        position,
        ellipse: ellipse,
        longitude: longitude || null,
        // label: {
        //   text: title,
        //   font: "14pt monospace",
        //   style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        //   outlineWidth: 2,
        //   verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        //   pixelOffset: new Cesium.Cartesian3(0.0, 32, 0.0),
        //   disableDepthTestDistance: Number.POSITIVE_INFINITY,
        // },
      });
      return point;
    },
    exit() {
      // 为false时为飞控中心创建航线
      this.$emit("quit");
    },
    testEmit(item) {
      this.$emit("add", item);
    },
  },
  inject: ["g_cesium_layer"],
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
@import "~@/styles/mixins.scss";

.cpt-command-airway-edit {
  position: absolute;
  left: 100px;
  top: 54px;
  width: 560px;
  box-sizing: border-box;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  z-index: 4;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 44px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 20px;
  }

  .ctx-box {
    height: calc(100% - 56px - 88px);
    box-sizing: border-box;
    padding: 25px 0px 0px 58px;
    overflow: auto;

    .form-box {
      ::v-deep .el-form {
        .divider {
          @include linear_gradient_border(0, auto, 0, 0);
          height: 22px;
        }

        .el-form-item {
          .item-group {
            display: flex;
            align-items: center;

            .unit {
              font-family: MicrosoftYaHeiUI;
              font-size: 18px;
              color: rgb(179, 201, 203);
              font-weight: 400;
              margin-left: 12px;
            }

            .iconfont {
              color: rgb(179, 201, 203);
              font-size: 30px;
              margin-left: 0.3em;
              cursor: pointer;
            }

            .el-icon-location-outline {
              color: rgb(179, 201, 203);
              font-size: 30px;
              margin-left: 0.3em;
              cursor: pointer;
            }
          }

          .el-form-item__label {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #ccedff;
            text-align: right;
            font-weight: 400;
          }

          .el-input {
            width: 356px;
            height: 40px;
            border: 1px solid #08c2d1;

            .el-input__inner {
              width: 100%;
              border: none;
              border-radius: 0;
              background-color: #000000;
              color: #fff;
            }
          }

          .el-input-number {
            width: 356px;

            .el-input-number__decrease,
            .el-input-number__increase {
              bottom: 1px;
              background: #606266;
              color: #f5f7fa;
              border-radius: 0;
              border: none;
            }

            .el-input-number__decrease {
              left: 1px;
            }

            .el-input-number__increase {
              right: -1px;
            }
          }
        }
      }
    }
  }

  .bottom-box {
    box-sizing: border-box;
    padding-bottom: 20px;
    height: 88px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @include linear_gradient_border(0, auto, 0, 0);

    .iconfont {
      cursor: pointer;
      font-size: 44px;
      color: #08c2d1;
    }
  }
}
</style>

<style lang="scss">
.actions-box {
  .title-box {
    display: flex;
    align-items: center;

    .title {
      font-size: 14px;
      width: 70px;
    }
  }

  .list-box {
    max-height: 210px;
    overflow: auto;
    margin-top: 12px;

    .action-item {
      background-color: #ececec;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #fff;
      box-sizing: border-box;
      padding: 12px;

      &:hover {
        background-color: #ccc;
      }

      .item-inner {
        display: flex;
        align-items: center;
      }

      .iconfont {
        color: red;
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
}
</style>
